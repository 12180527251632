import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "@bjelos-farm/lib-client-assets/assets/invoicelogo.png";
import { LocationPrintQueryFragmentFragment } from "../../Generated/graphql";

const styles = StyleSheet.create({
  sideBySide: {
    flexDirection: "row",
    marginBottom: 20,
  },
  image: {
    width: 100,
    marginBottom: 20,
  },
});

interface Props {
  location: LocationPrintQueryFragmentFragment;
  datetext: string;
}

export const Header: React.FC<Props> = ({ location, datetext }) => {
  const firmName =
    location.name === location.firm.name
      ? location.name
      : `${location.firm.name} ${location.name}`;
  return (
    <View>
      <View style={styles.sideBySide}>
        <View style={{ width: 300 }}>
          <Image style={styles.image} src={logo} />
          <Text style={{ fontFamily: "Helvetica-Bold" }}>
            Gala+Fabien Berlie
          </Text>
          <Text style={{ fontFamily: "Helvetica-Bold" }}>
            Fromage de chèvre fermier
          </Text>
          <Text>1170 Aubonne</Text>
          <Text>La Croix-de-Luisant 21</Text>
          <Text>078 847 11 74</Text>
          <Text>079 696 12 38</Text>
          <Text>021 565 73 00</Text>
          <Text>aubonne@fromagedechevre.ch</Text>
          <Text>IBAN CH36 0832 7765 0594 3467 3</Text>
          <Text>CH-BIO 006</Text>
        </View>
        {location.billingAddress ? (
          <View style={{ marginTop: 115 }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>{firmName}</Text>
            <Text>{location.billingAddress}</Text>
          </View>
        ) : (
          <View style={{ marginTop: 115 }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>{firmName}</Text>
            <Text>{location.address}</Text>
          </View>
        )}
      </View>
      <Text style={{ marginLeft: "auto", marginRight: 23 }}>{datetext}</Text>
    </View>
  );
};
