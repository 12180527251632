import React from "react";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { getDayEU, textFromDay } from "@bjelos-farm/lib-common-tools";
import { MyDatePicker, SpacedDivider } from "@bjelos-farm/lib-client-common";
import {
  OrderStatus,
  StickerColor,
  useOrdersLazyQuery,
} from "../../Generated/graphql";
import { ExposureNeg1, PlusOne } from "@material-ui/icons";
import { MyCard } from "../../Components/Cheesemonger/MyCard";
import { Sums, ProductSums } from "../../Components/Cheesemonger/Sums";

type ProductSummuray = {
  id: number;
  order: number;
  name: string;
  sums: ProductSums;
};

export const Cheesemonger: React.FC = () => {
  const [query, { data, loading, refetch }] = useOrdersLazyQuery();
  const [getDate, setDate] = React.useState<Date>(new Date());
  const [getExpanded, setExpanded] = React.useState<number>();

  const orders = data?.orders || [];

  const day = getDayEU(getDate);

  React.useEffect(() => {
    const fromDate = new Date(getDate.valueOf());
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(getDate.valueOf());
    toDate.setHours(23, 59, 59, 999);
    query({
      variables: {
        where: {
          when: { gte: fromDate, lte: toDate },
          status: { not: { equals: OrderStatus.Canceled } },
        },
      },
    });
  }, [getDate, query]);

  if (loading) {
    return <p>Chargement</p>;
  }

  const sums: ProductSummuray[] = orders.reduce<ProductSummuray[]>(
    (prev, order) => {
      const returnValue =
        order.cart.cartItems?.reduce<ProductSummuray[]>((prevTemp, item) => {
          const productId = item.productPrice.product.id;
          const existingProduct = prevTemp.find(
            (product) => product.id === productId,
          );
          if (!existingProduct) {
            prevTemp.push({
              id: productId,
              order:
                item.productPrice.product.order +
                100 * (item.productPrice.product.category?.order ?? 100),
              name: item.productPrice.product.category
                ? `${item.productPrice.product.category.name} - ${item.productPrice.product.name}`
                : item.productPrice.product.name,
              sums: {
                sum: 0,
                printBarcode: { sum: 0, details: [] },
                stickerGreen: { sum: 0, details: [] },
                noStickers: { sum: 0, details: [] },
                printDate: { sum: 0, details: [] },
                none: { sum: 0, details: [] },
                bulk: { sum: 0, details: [] },
              },
            });
          }
          const activeProduct =
            existingProduct ||
            prevTemp.find((product) => product.id === productId);
          if (!activeProduct) {
            return prevTemp;
          }
          const recipient =
            order.location.firm.name === order.location.name
              ? `${order.location.name}`
              : `${order.location.firm.name} - ${order.location.name}`;
          if (item.bulk) {
            activeProduct.sums.bulk.sum += item.qty;
            activeProduct.sums.bulk.details.push({
              for: recipient,
              qty: item.qty,
            });
            activeProduct.sums.sum += item.qty;
          } else if (order.location.stickerColor === StickerColor.Green) {
            activeProduct.sums.stickerGreen.sum += item.qty;
            activeProduct.sums.stickerGreen.details.push({
              for: recipient,
              qty: item.qty,
            });
            activeProduct.sums.sum += item.qty;
          } else if (order.location.stickerColor === StickerColor.None) {
            activeProduct.sums.noStickers.sum += item.qty;
            activeProduct.sums.noStickers.details.push({
              for: recipient,
              qty: item.qty,
            });
            activeProduct.sums.sum += item.qty;
          } else if (order.location.printBarcode) {
            activeProduct.sums.printBarcode.sum += item.qty;
            activeProduct.sums.printBarcode.details.push({
              for: recipient,
              qty: item.qty,
            });
            activeProduct.sums.sum += item.qty;
          } else if (order.location.printDate) {
            activeProduct.sums.printDate.sum += item.qty;
            activeProduct.sums.printDate.details.push({
              for: recipient,
              qty: item.qty,
            });
            activeProduct.sums.sum += item.qty;
          } else {
            activeProduct.sums.none.sum += item.qty;
            activeProduct.sums.none.details.push({
              for: recipient,
              qty: item.qty,
            });
            activeProduct.sums.sum += item.qty;
          }
          return prevTemp;
        }, JSON.parse(JSON.stringify(prev))) || prev;
      return returnValue;
    },
    [],
  );

  return (
    <>
      <Button variant="contained" onClick={() => refetch && refetch()}>
        Rafraichir
      </Button>
      <Typography variant="h2">Jours: {textFromDay(day)}</Typography>
      <div>
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() - 1);
            setDate(tempDate);
          }}
        >
          <ExposureNeg1 color="primary" />
        </IconButton>
        <MyDatePicker value={getDate} onChange={setDate} />
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() + 1);
            setDate(tempDate);
          }}
        >
          <PlusOne color="primary" />
        </IconButton>
      </div>
      <SpacedDivider space={40} />
      <Grid container spacing={3}>
        {sums
          .sort((product1, product2) => product1.order - product2.order)
          .map((sum, index) => (
            <Sums
              key={index}
              name={sum.name}
              sums={sum.sums}
              expanded={getExpanded === index}
              toggleExpanded={() =>
                getExpanded === index
                  ? setExpanded(undefined)
                  : setExpanded(index)
              }
            />
          ))}
      </Grid>
      <SpacedDivider space={40} />
      <Grid container spacing={3}>
        {orders.map((order, index) => (
          <MyCard data={order} key={index} />
        ))}
      </Grid>
    </>
  );
};
