import React from "react";
import { Document } from "@react-pdf/renderer";
import { OrderListFragment } from "../../Generated/graphql";
import { InvoicePage } from "./InvoicePage";

interface Props {
  orders: OrderListFragment[];
  date: Date;
  title: string;
}

export const NewInvoice: React.FC<Props> = ({ orders, title, date }) => {
  return (
    <Document title={title}>
      {orders.map((order, index) => (
        <InvoicePage order={order} date={date} key={index} />
      ))}
    </Document>
  );
};
