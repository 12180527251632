import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { OrderListFragment } from "../../Generated/graphql";

interface Props {
  order: OrderListFragment;
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100).replace(".", "'");

const styles = StyleSheet.create({
  root: { marginTop: "auto", height: 100 },
  sideBySide: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  date: {
    fontWeight: "semibold",
    fontSize: 12,
  },
  total: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2 10 2 10",
    marginBottom: 4,
    width: "180",
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    fontWeight: "semibold",
    fontSize: 10,
  },
  superTotal: {
    color: "white",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2 10 2 10",
    marginBottom: 5,
    width: "180",
    borderRadius: 10,
    backgroundColor: "#B15B10",
    fontWeight: "semibold",
    fontSize: 10,
  },
  bottom: {
    marginTop: 10,
    color: "#8E8E8E",
    fontWeight: "semibold",
    fontSize: 8,
    marginLeft: "auto",
    marginRight: "auto",
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  underlined: {
    textDecoration: "underline",
    paddingRight: 2,
  },
});

export const Footer: React.FC<Props> = ({ order }) => {
  const total =
    order.cart.cartItems?.reduce<number>(
      (previous, current) =>
        previous + current.productPrice.price * current.qty,
      0,
    ) || 0;
  return (
    <View style={styles.root}>
      <View style={styles.sideBySide}>
        <Text style={styles.date}>Acquitté le :</Text>
        <View>
          <View style={styles.total}>
            <Text>Montant HT</Text>
            <Text>{formatPrice2(total)}</Text>
          </View>
          <View style={styles.total}>
            <Text>TVA (2,5%)</Text>
            <Text>{formatPrice2(total * 0.025)}</Text>
          </View>
          <View style={styles.superTotal}>
            <Text>Total (CHF):</Text>
            <Text>{formatPrice2(total * 1.025)}</Text>
          </View>
        </View>
      </View>
      <View style={styles.bottom}>
        <Text style={styles.center}>La Croix-de-Luisant 21, 1170 Aubonne</Text>
        <Text style={styles.center}>
          078 847 11 74 - 079 696 12 38 - 021 565 73 00
        </Text>
        <View style={styles.sideBySide}>
          <Text style={styles.underlined}>aubonne@fromagedechevre.ch</Text>
          <Text>- CHE.358.221.637 TVA</Text>
        </View>
      </View>
    </View>
  );
};
