import React from "react";
import { Font, Page, StyleSheet } from "@react-pdf/renderer";
import { LocationPrintQueryFragmentFragment } from "../../Generated/graphql";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Table } from "./Table";

Font.register({
  family: "OpenSans",
  fonts: [
    { src: process.env.PUBLIC_URL + "/fonts/OpenSans-Regular.ttf" },
    {
      fontWeight: "bold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-Bold.ttf",
    },
    {
      fontWeight: "semibold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-SemiBold.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "OpenSans",
    fontSize: 9,
    paddingTop: 42,
    paddingLeft: 50,
    paddingRight: 43,
    paddingBottom: 30,
  },
});

interface Props {
  location: LocationPrintQueryFragmentFragment;
  datetext: string;
}

type ProductSummary = {
  id: number;
  order: number;
  name: string;
  price: number;
  sums: { sum: number; detail: { date: number; qty: number }[] };
};

export const InvoicePage: React.FC<Props> = ({ location, datetext }) => {
  if (!location.orders || !location.orders[0]) return <></>;
  const sums: ProductSummary[] = location.orders
    .reduce<ProductSummary[]>((prev, order) => {
      if (!order.cart.cartItems) {
        return prev;
      }
      const day = new Date(order.when).getDate();
      return order.cart.cartItems.reduce<ProductSummary[]>((tempPrev, item) => {
        const productId = item.productPrice.product.id;
        const existingProduct = tempPrev.find(
          (product) => product.id === productId,
        );
        if (!existingProduct) {
          tempPrev.push({
            id: productId,
            order:
              item.productPrice.product.order +
              100 * (item.productPrice.product.category?.order ?? 100),
            price: item.productPrice.price,
            name: item.productPrice.product.category
              ? `${item.productPrice.product.category.name} - ${item.productPrice.product.name}`
              : item.productPrice.product.name,
            sums: { sum: item.qty, detail: [{ qty: item.qty, date: day }] },
          });
        } else {
          existingProduct.sums.sum += item.qty;
          existingProduct.sums.detail.push({ qty: item.qty, date: day });
        }
        return tempPrev;
      }, JSON.parse(JSON.stringify(prev)));
    }, [])
    .sort((product1, product2) => product1.order - product2.order);

  const total =
    sums.reduce<number>(
      (previous, current) => previous + current.price * current.sums.sum,
      0,
    ) || 0;
  return (
    <Page size="A4" style={styles.page}>
      <Header location={location} datetext={datetext} />
      <Table location={location} sums={sums} />
      <Footer total={total} invoiceDate="19.10.2021" />
    </Page>
  );
};
