import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { getDayEU, textFromDay } from "@bjelos-farm/lib-common-tools";
import { MyDatePicker, SpacedDivider } from "@bjelos-farm/lib-client-common";
import { OrderStatus, useOrdersLazyQuery } from "../../Generated/graphql";
import { ExposureNeg1, PlusOne } from "@material-ui/icons";
import { MyCard } from "../../Components/Delivery/MyCard";

export const Delivery: React.FC = () => {
  const [query, { data, loading, refetch }] = useOrdersLazyQuery();
  const [getDate, setDate] = React.useState<Date>(new Date());

  const orders = data?.orders || [];

  const day = getDayEU(getDate);

  React.useEffect(() => {
    const fromDate = new Date(getDate.valueOf());
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(getDate.valueOf());
    toDate.setHours(23, 59, 59, 999);
    query({
      variables: {
        where: {
          when: { gte: fromDate, lte: toDate },
          status: { not: { equals: OrderStatus.Canceled } },
        },
      },
    });
  }, [getDate, query]);

  if (loading) {
    return <p>Chargement</p>;
  }
  return (
    <>
      <Typography variant="h2">Jours: {textFromDay(day)}</Typography>
      <div>
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() - 1);
            setDate(tempDate);
          }}
        >
          <ExposureNeg1 color="primary" />
        </IconButton>
        <MyDatePicker value={getDate} onChange={setDate} />
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() + 1);
            setDate(tempDate);
          }}
        >
          <PlusOne color="primary" />
        </IconButton>
      </div>
      <Grid container spacing={3}>
        {orders
          .filter((order) => !order.deliveredAt)
          .map((order, index) => (
            <MyCard
              data={order}
              key={index}
              refetch={() => refetch && refetch()}
            />
          ))}
      </Grid>
      <SpacedDivider space={40} />
      <Typography variant="h4">Livraisons effectuées</Typography>
      <Grid container spacing={3}>
        {orders
          .filter((order) => order.deliveredAt)
          .map((order, index) => (
            <MyCard
              data={order}
              key={index}
              refetch={() => refetch && refetch()}
            />
          ))}
      </Grid>
    </>
  );
};
