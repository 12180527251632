import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { makeItTwo } from "@bjelos-farm/lib-common-tools";
import { LocationPrintQueryFragmentFragment } from "../../Generated/graphql";

const marginBase = 3;
const paddingBase = 2;
const paddingWithRoundBase = 8;

const styles = StyleSheet.create({
  table: {
    flexGrow: 1,
  },
  firstQty: {
    width: "45%",
    padding: 4,
    paddingLeft: 2 * paddingWithRoundBase,
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    fontWeight: "semibold",
    fontSize: 10,
    marginBottom: marginBase,
  },
  firstRow: {
    flexDirection: "row",
    width: "100%",
    fontWeight: "semibold",
    fontSize: 10,
    marginBottom: marginBase,
  },
  leftSideFirstRow: {
    width: "45%",
    flexDirection: "row",
  },
  rightSideFirstRow: {
    width: "55%",
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    paddingTop: paddingBase,
    paddingBottom: paddingBase,
  },
  firstDate: {
    borderRadius: 10,
    backgroundColor: "#B15B10",
    color: "white",
    paddingTop: paddingBase,
    paddingBottom: paddingBase,
  },
  firstDateBox: {
    paddingLeft: marginBase,
    paddingRight: marginBase,
  },
  nameFirst: {
    width: "45%",
  },
  unitPriceFirst: {
    width: "30%",
  },
  itemPriceFirst: {
    width: "25%",
  },
  row: {
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#D2D2D2",
  },
  date: {
    padding: paddingBase,
  },
  qty: {
    width: "45%",
    flexDirection: "row",
  },
  name: {
    width: "28%",
    padding: paddingBase,
    backgroundColor: "#F9F2EC",
  },
  unitPrice: {
    width: "14%",
    padding: paddingBase,
  },
  itemPrice: {
    width: "13%",
    padding: paddingBase,
    backgroundColor: "#F9F2EC",
  },
  total: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "auto",
    width: "40%",
    padding: paddingBase,
    borderWidth: paddingBase,
  },
  textRight: {
    marginLeft: "auto",
    paddingRight: paddingWithRoundBase,
  },
  textLeft: {
    paddingLeft: paddingWithRoundBase,
  },
  textCenter: {
    marginHorizontal: "auto",
  },
});

interface Props {
  location: LocationPrintQueryFragmentFragment;
  sums: ProductSummary[];
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100).replace(".", "'");

export const priceFormatter3 = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export const formatPrice3 = (input: number) =>
  priceFormatter3.format(input / 100).replace(".", "'");

type ProductSummary = {
  id: number;
  order: number;
  name: string;
  price: number;
  sums: { sum: number; detail: { date: number; qty: number }[] };
};

export const Table: React.FC<Props> = ({ location, sums }) => {
  if (!location.orders || !location.orders[0]) return <></>;
  const orders = location.orders ?? [];
  const firstDate = new Date(orders[0].when);
  const dateExtension = `.${makeItTwo(
    firstDate.getMonth() + 1,
  )}.${firstDate.getFullYear()}`;

  const orderQty = orders.length || 1;
  const width = 100 / (orderQty + 1) + "%";
  return (
    <View style={styles.table}>
      <View style={styles.firstQty}>
        <Text>Quantité</Text>
      </View>
      <View style={styles.firstRow}>
        <View style={styles.leftSideFirstRow}>
          {orders.map((_, index) => {
            return (
              <View
                key={index}
                style={{
                  ...styles.firstDateBox,
                  width: width,
                }}
              >
                <View style={styles.firstDate}>
                  <Text style={styles.textCenter}>L{index + 1}</Text>
                </View>
              </View>
            );
          })}
          <View
            style={{
              ...styles.firstDateBox,
              width: width,
            }}
          >
            <View style={styles.firstDate}>
              <Text style={styles.textCenter}>Total</Text>
            </View>
          </View>
        </View>
        <View style={styles.rightSideFirstRow}>
          <View style={styles.nameFirst}>
            <Text style={styles.textLeft}>Désignation</Text>
          </View>
          <View style={styles.unitPriceFirst}>
            <Text style={styles.textRight}>Prix unité</Text>
          </View>
          <View style={styles.itemPriceFirst}>
            <Text style={styles.textRight}>Prix</Text>
          </View>
        </View>
      </View>
      {sums.map((item, index) => {
        return (
          <View style={styles.row} key={index}>
            <View style={styles.qty}>
              {(orders || []).map((order, index) => {
                const day = new Date(order.when).getDate();
                const value =
                  item.sums.detail.find((detail) => detail.date === day)?.qty ||
                  0;
                return (
                  <View
                    style={{
                      ...styles.date,
                      width: width,
                      ...((orderQty - index) % 2 === 1
                        ? { backgroundColor: "#F9F2EC" }
                        : {}),
                    }}
                  >
                    <Text style={styles.textCenter}>{value || ""}</Text>
                  </View>
                );
              })}
              <View style={{ ...styles.date, width: width }}>
                <Text style={styles.textCenter}>{item.sums.sum}</Text>
              </View>
            </View>
            <View style={styles.name}>
              <Text style={styles.textLeft}>{item.name}</Text>
            </View>
            <View style={styles.unitPrice}>
              <Text style={styles.textRight}>{formatPrice3(item.price)}</Text>
            </View>
            <View style={styles.itemPrice}>
              <Text style={styles.textRight}>
                {formatPrice2(item.price * item.sums.sum)}
              </Text>
            </View>
          </View>
        );
      })}
      {orders.map((order, index) => {
        const day = new Date(order.when).getDate();
        const total =
          order.cart.cartItems?.reduce<number>(
            (previous, current) =>
              previous + current.productPrice.price * current.qty,
            0,
          ) || 0;

        return (
          <View
            key={index}
            style={{ flexDirection: "row", marginTop: marginBase }}
          >
            <View
              style={{
                ...styles.firstDate,
                width: 35,
              }}
            >
              <Text style={styles.textCenter}>L{index + 1}</Text>
            </View>
            <Text
              style={{ marginLeft: 3 * marginBase, paddingTop: paddingBase }}
            >
              Livraison du {makeItTwo(day)}
              {dateExtension}
              {order.externalRef ? ` (${order.externalRef})` : ""}
            </Text>
            <View
              style={{
                width: 80,
              }}
            >
              <Text
                style={{
                  marginLeft: "auto",
                  paddingTop: paddingBase,
                  fontWeight: "bold",
                  color: "#B15B10",
                }}
              >
                {formatPrice2(total)} CHF
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};
