import React from "react";
import { Document } from "@react-pdf/renderer";
import { LocationPrintQueryFragmentFragment } from "../../Generated/graphql";
import { InvoicePage } from "./InvoicePage";

interface Props {
  locations: LocationPrintQueryFragmentFragment[];
  title: string;
  datetext: string;
}

export const Montly: React.FC<Props> = ({ locations, title, datetext }) => {
  return (
    <Document title={title}>
      {locations.map((location, index) => (
        <InvoicePage location={location} key={index} datetext={datetext} />
      ))}
    </Document>
  );
};
