import React, { createContext, useContext } from "react";
import { useMaster } from "@bjelos-farm/lib-client-common";
import {
  UserFragment,
  ProductFragment,
  useGetAllQuery,
} from "../Generated/graphql";

type DataContextType = {
  me: UserFragment;
  products: ProductFragment[];
  refetch: () => void;
};

const DataDefinition = createContext<DataContextType | undefined>(undefined);

export const useData = () => useContext(DataDefinition)!;

export const DataProvider: React.FC = ({ children }) => {
  const { getJwt, getErrors } = useMaster();
  const { data, refetch } = useGetAllQuery({
    skip: !getJwt || getErrors.length !== 0,
  });
  if (!data?.me) return <></>;
  return (
    <DataDefinition.Provider
      value={{
        me: data.me,
        products: data.products,
        refetch,
      }}
    >
      {children}
    </DataDefinition.Provider>
  );
};
