import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { OrderListFragment } from "../../Generated/graphql";

const styles = StyleSheet.create({
  table: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  firstRow: {
    flexDirection: "row",
    width: "100%",
    borderWidth: 2,
  },
  firstQty: {
    width: "10%",
    padding: 2,
    borderRightWidth: 2,
  },
  firstName: {
    width: "50%",
    padding: 2,
    borderRightWidth: 2,
  },
  firstUnitPrice: {
    width: "20%",
    padding: 2,
    borderRightWidth: 2,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  qty: {
    width: "10%",
    padding: 2,
    borderRightWidth: 1,
  },
  name: {
    width: "50%",
    padding: 2,
    borderRightWidth: 1,
  },
  unitPrice: {
    width: "20%",
    padding: 2,
    borderRightWidth: 1,
  },
  itemPrice: {
    width: "20%",
    padding: 2,
  },
  total: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "auto",
    width: "40%",
    padding: 2,
    borderWidth: 2,
  },
});

interface Props {
  order: OrderListFragment;
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

export const priceFormatter3 = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export const formatPrice3 = (input: number) =>
  priceFormatter3.format(input / 100);

type Product = {
  qty: number;
  name: string;
  price: number;
  order: number;
};

export const Table: React.FC<Props> = ({ order }) => {
  const products: Product[] = (order.cart.cartItems || [])
    .map((item) => ({
      name: item.productPrice.product.category
        ? `${item.productPrice.product.category.name} ${item.productPrice.product.name}`
        : item.productPrice.product.name,
      order:
        item.productPrice.product.order +
        100 * (item.productPrice.product.category?.order ?? 100),
      price: item.productPrice.price,
      qty: item.qty,
    }))
    .sort((product1, product2) => product1.order - product2.order);
  const total =
    order.cart.cartItems?.reduce<number>(
      (previous, current) =>
        previous + current.productPrice.price * current.qty,
      0,
    ) || 0;
  return (
    <View style={styles.table}>
      <View style={styles.firstRow}>
        <View style={styles.firstQty}>
          <Text
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Qté
          </Text>
        </View>
        <View style={styles.firstName}>
          <Text>Désignation</Text>
        </View>
        <View style={styles.firstUnitPrice}>
          <Text
            style={{
              marginLeft: "auto",
            }}
          >
            Prix unité
          </Text>
        </View>
        <View style={styles.itemPrice}>
          <Text
            style={{
              marginLeft: "auto",
            }}
          >
            Prix
          </Text>
        </View>
      </View>
      {products.map((item, index) => {
        return (
          <View style={styles.row} key={index}>
            <View style={styles.qty}>
              <Text
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {item.qty}
              </Text>
            </View>
            <View style={styles.name}>
              <Text>{item.name}</Text>
            </View>
            <View style={styles.unitPrice}>
              <Text
                style={{
                  marginLeft: "auto",
                }}
              >
                {formatPrice3(item.price)}
              </Text>
            </View>
            <View style={styles.itemPrice}>
              <Text
                style={{
                  marginLeft: "auto",
                }}
              >
                {formatPrice2(item.price * item.qty)}
              </Text>
            </View>
          </View>
        );
      })}
      <View style={styles.total}>
        <Text>Total (CHF):</Text>
        <Text>{formatPrice2(total)}</Text>
      </View>
    </View>
  );
};
