import React from "react";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import {
  fileNameDate,
  getDayEU,
  textFromDay,
} from "@bjelos-farm/lib-common-tools";
import {
  MyDatePicker,
  SpacedDivider,
  StyledLink,
} from "@bjelos-farm/lib-client-common";
import { OrderStatus, useOrdersLazyQuery } from "../../Generated/graphql";
import { ExposureNeg1, PlusOne } from "@material-ui/icons";
import { Invoice } from "../../Pdf/Invoice";
import { NewInvoice } from "../../Pdf/NewInvoice";
import { PdfDownloadButton } from "../../Components/PdfDownloadButton";
import { slufigy } from "../../Utils/slugify";

export const PDFOrders: React.FC = () => {
  const [query, { data, loading, refetch }] = useOrdersLazyQuery();
  const [getDate, setDate] = React.useState<Date>(new Date());

  const orders = data?.orders || [];

  const day = getDayEU(getDate);

  React.useEffect(() => {
    const fromDate = new Date(getDate.valueOf());
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(getDate.valueOf());
    toDate.setHours(23, 59, 59, 999);
    query({
      variables: {
        where: {
          when: { gte: fromDate, lte: toDate },
          status: { not: { equals: OrderStatus.Canceled } },
        },
      },
    });
  }, [getDate, query]);

  const InvoiceToUse = getDate.valueOf() < 1630447200000 ? Invoice : NewInvoice;

  if (loading) {
    return <p>Chargement</p>;
  }
  return (
    <>
      <Grid container justify="space-between">
        <Button variant="contained" onClick={() => refetch && refetch()}>
          Rafraichir
        </Button>
        <StyledLink to="/print/month">
          <Button variant="contained">Mois</Button>
        </StyledLink>
      </Grid>
      <Typography variant="h2">Jours: {textFromDay(day)}</Typography>
      <div>
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() - 1);
            setDate(tempDate);
          }}
        >
          <ExposureNeg1 color="primary" />
        </IconButton>
        <MyDatePicker value={getDate} onChange={setDate} />
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() + 1);
            setDate(tempDate);
          }}
        >
          <PlusOne color="primary" />
        </IconButton>
      </div>
      <SpacedDivider space={40} />
      <PdfDownloadButton
        showfilename
        filename={`facture-complet-${fileNameDate(getDate)}`}
        document={
          <InvoiceToUse
            orders={orders}
            title={`facture-complet-${fileNameDate(getDate)}`}
            date={getDate}
          />
        }
      />
      <SpacedDivider space={40} />
      {orders.map((order, index) => {
        const text = order.location.monthly ? "livraison" : "facture";
        const firmName =
          order.location.name === order.location.firm.name
            ? order.location.name
            : `${order.location.firm.name} ${order.location.name}`;
        const filename = `${text}-${slufigy(firmName)}-${fileNameDate(
          getDate,
        )}`;
        return (
          <PdfDownloadButton
            key={index}
            showfilename
            filename={filename}
            document={
              <InvoiceToUse orders={[order]} title={filename} date={getDate} />
            }
          />
        );
      })}
    </>
  );
};
