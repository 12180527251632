import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import MaterialTable, { MTableBody } from "material-table";
import { SpacedDivider, formatPrice } from "@bjelos-farm/lib-client-common";
import {
  OrderStatus,
  useSetOrderDeliveredMutation,
  useOrderEditMutation,
  useOrdersQuery,
} from "../../Generated/graphql";
import { humanReadableDate } from "@bjelos-farm/lib-common-tools";

export const Orders: React.FC = () => {
  const filterRef2 = React.useRef(null);
  const filterRef3 = React.useRef(null);
  const filterRef4 = React.useRef(null);
  const [getOpen, setOpen] = React.useState<
    | {
        id: number;
        externalRef: string;
      }
    | undefined
  >(undefined);
  const history = useHistory();
  const { data, loading, refetch } = useOrdersQuery();
  const [setDelivered] = useSetOrderDeliveredMutation({
    update: () => refetch && refetch(),
  });

  const [orderEdit] = useOrderEditMutation({
    update: () => {
      setOpen(undefined);
      refetch && refetch();
    },
  });

  const orders = data?.orders;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!orders) {
    return <p>Element non trouvé</p>;
  }

  return (
    <Card>
      <CardHeader title="Commandes" />
      <SpacedDivider />
      <CardContent>
        <MaterialTable
          components={{
            Body: (props) => (
              <MTableBody
                {...props}
                onFilterChanged={(columnId: any, value: any) => {
                  props.onFilterChanged(columnId, value);
                  if (columnId === 2) filterRef2.current = value;
                  if (columnId === 3) filterRef3.current = value;
                  if (columnId === 4) filterRef4.current = value;
                }}
              />
            ),
          }}
          title="Commandes"
          data={
            orders.map((order) => ({
              ...order,
              isDelivered: order.deliveredAt ? true : false,
            })) || []
          }
          options={{ filtering: true, pageSize: 10 }}
          detailPanel={(rowData) => {
            return (
              <div>
                {(rowData.cart.cartItems || []).map((item) => (
                  <p>
                    {item.qty} *
                    {item.productPrice.product.category
                      ? ` ${item.productPrice.product.category.name} - `
                      : ""}
                    {` ${item.productPrice.product.name}`}
                    {` (${formatPrice(item.productPrice.price)})`}
                    {` = ${formatPrice(item.productPrice.price * item.qty)}`}
                  </p>
                ))}
                <p>
                  Total =
                  {` ${formatPrice(
                    (rowData.cart.cartItems || []).reduce<number>(
                      (prev, item) => {
                        return prev + item.productPrice.price * item.qty;
                      },
                      0,
                    ),
                  )}`}
                </p>
                {rowData.status === OrderStatus.Paid && (
                  <Button
                    onClick={() => {
                      orderEdit({
                        variables: {
                          id: rowData.id,
                          data: {
                            status: {
                              set: OrderStatus.Active,
                            },
                          },
                        },
                      });
                    }}
                    color="primary"
                  >
                    Noter comme non payé
                  </Button>
                )}
                {rowData.status === OrderStatus.Active && (
                  <Button
                    onClick={() => {
                      orderEdit({
                        variables: {
                          id: rowData.id,
                          data: {
                            status: {
                              set: OrderStatus.Paid,
                            },
                          },
                        },
                      });
                    }}
                    color="primary"
                  >
                    Noter comme payé
                  </Button>
                )}
              </div>
            );
          }}
          columns={[
            {
              title: "Livraison prévue",
              field: "when",
              render: ({ when }) => humanReadableDate(new Date(when)),
              filtering: false,
            },
            {
              title: "Livré à",
              field: "deliveredAt",
              render: ({ deliveredAt }) =>
                deliveredAt
                  ? humanReadableDate(new Date(deliveredAt))
                  : "Non livré",
              filtering: false,
            },
            {
              title: "Livré ?",
              field: "isDelivered",
              lookup: {
                true: "Oui",
                false: "Non",
              },
              defaultFilter: filterRef2.current,
            },
            {
              title: "Status",
              field: "status",
              lookup: {
                CANCELED: "Annulée",
                ACTIVE: "Non payé",
                PAID: "Payé",
              },
              defaultFilter: filterRef3.current,
            },
            {
              title: "Ref",
              field: "externalRef",
              render: ({ externalRef, id }) =>
                externalRef ? (
                  <div>
                    {externalRef}
                    <IconButton
                      color="inherit"
                      onClick={() => setOpen({ id, externalRef })}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton
                    color="inherit"
                    onClick={() =>
                      setOpen({ id, externalRef: externalRef || "" })
                    }
                  >
                    <Add />
                  </IconButton>
                ),
              defaultFilter: filterRef4.current,
            },
          ]}
          actions={[
            (order) => ({
              icon:
                order.status === OrderStatus.Canceled
                  ? "restore_from_trash"
                  : "delete",
              tooltip: "Supprimer",
              onClick: () => {
                orderEdit({
                  variables: {
                    id: order.id,
                    data: {
                      status: {
                        set:
                          order.status === OrderStatus.Active
                            ? OrderStatus.Canceled
                            : OrderStatus.Active,
                      },
                    },
                  },
                });
              },
            }),
            (order) => ({
              icon: "open_in_new",
              tooltip: "Modifier",
              onClick: () => {
                history.push(`/delivery/${order.id}/update`);
              },
            }),
            (order) => ({
              icon: "menu_open",
              tooltip: "Livrer à la date prévue",
              onClick: () => {
                setDelivered({
                  variables: {
                    where: { id: order.id },
                    data: { deliveredAt: { set: new Date(order.when) } },
                  },
                });
              },
              hidden: order.deliveredAt,
            }),
            (order) => ({
              icon: "open_in_browser",
              tooltip: "Livrer maintenant",
              onClick: () => {
                setDelivered({
                  variables: {
                    where: { id: order.id },
                    data: { deliveredAt: { set: new Date() } },
                  },
                });
              },
              hidden: order.deliveredAt,
            }),
          ]}
        />
      </CardContent>
      <Dialog open={getOpen !== undefined} onClose={() => setOpen(undefined)}>
        <DialogTitle>Nouvelle référence</DialogTitle>
        <DialogContent>
          <DialogContentText>Inscrire la nouvelle référence</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Référence"
            fullWidth
            value={getOpen?.externalRef || ""}
            onChange={(value) =>
              setOpen(
                getOpen
                  ? { id: getOpen.id, externalRef: value.target.value }
                  : undefined,
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(undefined)} color="primary">
            Annuler
          </Button>
          <Button
            onClick={() =>
              getOpen &&
              orderEdit({
                variables: {
                  id: getOpen.id,
                  data: { externalRef: { set: getOpen.externalRef } },
                },
              })
            }
            color="primary"
          >
            Appliquer la ref
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
