import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "@bjelos-farm/lib-client-assets/assets/invoicelogo.png";
import { OrderListFragment } from "../../Generated/graphql";
import { invoiceDate } from "@bjelos-farm/lib-common-tools";

const height = 118;

const styles = StyleSheet.create({
  root: {
    fontSize: 11,
    color: "#151515",
    marginBottom: 5,
  },
  sideBySide: {
    height: height,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  right: { alignItems: "flex-end", marginTop: 15 },
  date: { fontWeight: "bold", fontSize: 12 },
  type: { fontWeight: "bold", fontSize: 24 },
  bold: { fontWeight: "semibold" },
  location: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  locationSmaller: {
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: "auto",
    marginRight: "auto",
  },
  image: {
    height: height,
  },
});

interface Props {
  order: OrderListFragment;
  date: Date;
}

export const Header: React.FC<Props> = ({ order, date }) => {
  const firmName =
    order.location.name === order.location.firm.name
      ? order.location.name
      : `${order.location.firm.name} ${order.location.name}`;
  return (
    <View style={styles.root}>
      <View style={styles.sideBySide}>
        <View>
          <Image style={styles.image} src={logo} />
        </View>
        <View style={styles.right}>
          <Text style={styles.date}>{invoiceDate(date)}</Text>
          <Text style={styles.type}>
            {order.location.monthly ? "LIVRAISON" : "FACTURE"}
          </Text>
          <Text style={styles.bold}>Gala + Fabien Berlie</Text>
          <Text>Fromage de chèvre fermier</Text>
          <Text>CH-BIO 006</Text>
        </View>
      </View>
      <Text
        style={firmName.length > 26 ? styles.locationSmaller : styles.location}
      >
        {firmName}
      </Text>
    </View>
  );
};
