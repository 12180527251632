import React from "react";
import { CardList, looseMatch } from "@bjelos-farm/lib-client-common";
import { MyCard } from "../../Components/Locations/MyCard";
import {
  useGetFirmsQuery,
  LocationOnFirmListFragment,
} from "../../Generated/graphql";

export const Locations: React.FC = () => {
  const { data, loading } = useGetFirmsQuery();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  if (!data?.firms || loading) return <p>Chargement</p>;

  return (
    <CardList
      paginationBase={12}
      data={data.firms.reduce<LocationOnFirmListFragment[]>((prev, current) => {
        return [
          ...prev,
          ...(current.locations?.map(
            (location): LocationOnFirmListFragment => ({
              ...location,
              name: `${current.name} - ${location.name}`,
            }),
          ) || []),
        ];
      }, [])}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter(
          (element) =>
            (!element.disabled || getShowDisabled) &&
            looseMatch(filter, element.name),
        )
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Cacher cachés" : "Montrer désactivés",
        },
      ]}
    />
  );
};
