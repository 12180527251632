import { RouteDefinitionType } from "@bjelos-farm/lib-client-common";
import {
  Phone,
  Description,
  DriveEta,
  Archive,
  AccountBalance,
  SyncAlt,
  Today,
} from "@material-ui/icons";

import { Schedule } from "./Pages/Schedule";
import { Create } from "./Pages/Call/Create";
import { Delivery } from "./Pages/Delivery";
import { Update } from "./Pages/Orders/Update";
import { PDFOrders } from "./Pages/Pdf/Orders";
import { Month } from "./Pages/Pdf/Month";
import { Cheesemonger } from "./Pages/Cheesemonger";
import { Locations } from "./Pages/Locations";
import { Location } from "./Pages/Locations/Location";
import { CreateOrder } from "./Pages/Locations/CreateOrder";
import { Orders } from "./Pages/Orders";
import { Monthly } from "./Pages/Monthly";
// import { TempPdf } from "./Pages/Pdf/TempPdf";

export const routes: RouteDefinitionType[] = [
  {
    icon: Phone,
    mainComponent: Schedule,
    path: "/schedule",
    title: "Appels",
    subRoutes: [
      { path: "/:id/call", component: Create },
      { path: "/:id", component: Schedule },
    ],
  },
  {
    icon: DriveEta,
    mainComponent: Delivery,
    path: "/delivery",
    title: "Livraison",
    subRoutes: [{ path: "/:id/update", component: Update }],
  },
  {
    icon: Description,
    mainComponent: PDFOrders,
    path: "/print",
    title: "Impression",
    subRoutes: [{ path: "/month", component: Month }],
  },
  {
    icon: Archive,
    mainComponent: Cheesemonger,
    path: "/cheesemonger",
    title: "Fromagerie",
    subRoutes: [],
  },
  {
    icon: AccountBalance,
    mainComponent: Locations,
    path: "/locations",
    title: "Localisation",
    subRoutes: [
      { path: "/:id/order", component: CreateOrder },
      { path: "/:id", component: Location },
    ],
  },
  {
    icon: SyncAlt,
    mainComponent: Orders,
    path: "/orders",
    title: "Toute les commandes",
    subRoutes: [],
  },
  {
    icon: Today,
    mainComponent: Monthly,
    path: "/monthly",
    title: "Les commandes mensuels",
    subRoutes: [],
  },
  // {
  //   icon: SyncAlt,
  //   mainComponent: TempPdf,
  //   path: "/temp",
  //   title: "Temporaire",
  //   subRoutes: [],
  // },
];
