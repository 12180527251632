import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  firstRow: {
    padding: 2,
    flexDirection: "row",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    fontWeight: "semibold",
    fontSize: 10,
  },
  col1: {
    paddingLeft: 10,
    width: "10%",
    textAlign: "center",
    marginRight: 28,
  },
  col2: {
    width: "46%",
  },
  col3: {
    width: "16%",
    textAlign: "right",
  },
  col4: {
    width: "16%",
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#D2D2D2",
    padding: 2,
  },
  lastRow: {
    flexDirection: "row",
    width: "100%",
    padding: 2,
  },
});

interface Props {
  values: [number, number, number];
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

export const priceFormatter3 = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export const formatPrice3 = (input: number) =>
  priceFormatter3.format(input / 100);

export const Table: React.FC<Props> = ({ values }) => {
  return (
    <View>
      <View style={styles.firstRow}>
        <Text style={styles.col1}>Qté</Text>
        <Text style={styles.col2}>Désignation</Text>
        <Text style={styles.col3}>Prix unité</Text>
        <Text style={styles.col4}>Prix</Text>
      </View>
      {[
        {
          qty: 1,
          name: "TVA cumulée du premier trimestre",
          price: values[0],
        },
        {
          qty: 1,
          name: "TVA cumulée du deuxième trimestre",
          price: values[1],
        },
        {
          qty: 1,
          name: "TVA cumulée de juillet et août",
          price: values[2],
        },
      ].map((item, index) => {
        return (
          <View style={styles.row} key={index}>
            <Text style={styles.col1}>{item.qty}</Text>
            <Text style={styles.col2}>{item.name}</Text>
            <Text style={styles.col3}>{formatPrice3(item.price)}</Text>
            <Text style={styles.col4}>
              {formatPrice2(item.price * item.qty)}
            </Text>
          </View>
        );
      })}
    </View>
  );
};
