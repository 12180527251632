import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { SpacedDivider } from "@bjelos-farm/lib-client-common";

interface Props {
  disabled: boolean;
  substraction: number;
  substractionChange: (value: number) => void;
  substractive: boolean;
  substractiveChange: (bulk: boolean) => void;
  value: number;
  valueChange: (value: number) => void;
  bulk: boolean;
  bulkChange: (bulk: boolean) => void;
}

interface ButtonProps {
  disabled: boolean;
  content: string;
  onClick: () => void;
  active?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  disabled,
  content,
  onClick,
  active = false,
}) => {
  const [getClicked, setClicked] = React.useState<boolean>(false);
  return (
    <Grid item xs={4}>
      <Card
        style={{
          height: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          WebkitUserSelect: "none",
        }}
        elevation={getClicked || active ? 1 : 10}
        onMouseDown={disabled ? () => {} : () => setClicked(true)}
        onMouseUp={
          disabled ? () => {} : () => setTimeout(() => setClicked(false), 70)
        }
        onMouseLeave={disabled ? () => {} : () => setClicked(false)}
        onClick={disabled ? () => {} : onClick}
      >
        <Typography
          variant={active ? "h3" : "h5"}
          color={
            disabled ? "textSecondary" : active ? "primary" : "textPrimary"
          }
        >
          {content}
        </Typography>
      </Card>
    </Grid>
  );
};

export const Numpad: React.FC<Props> = ({
  disabled,
  substraction,
  substractionChange,
  substractive,
  substractiveChange,
  value,
  valueChange,
  bulk,
  bulkChange,
}) => {
  const activeValue = substractive ? substraction : value;
  const activeValueChange = substractive ? substractionChange : valueChange;
  return (
    <div>
      <Grid container spacing={3}>
        <Button
          disabled={disabled}
          content="1"
          onClick={() => activeValueChange(activeValue * 10 + 1)}
        />
        <Button
          disabled={disabled}
          content="2"
          onClick={() => activeValueChange(activeValue * 10 + 2)}
        />
        <Button
          disabled={disabled}
          content="3"
          onClick={() => activeValueChange(activeValue * 10 + 3)}
        />
        <Button
          disabled={disabled}
          content="4"
          onClick={() => activeValueChange(activeValue * 10 + 4)}
        />
        <Button
          disabled={disabled}
          content="5"
          onClick={() => activeValueChange(activeValue * 10 + 5)}
        />
        <Button
          disabled={disabled}
          content="6"
          onClick={() => activeValueChange(activeValue * 10 + 6)}
        />
        <Button
          disabled={disabled}
          content="7"
          onClick={() => activeValueChange(activeValue * 10 + 7)}
        />
        <Button
          disabled={disabled}
          content="8"
          onClick={() => activeValueChange(activeValue * 10 + 8)}
        />
        <Button
          disabled={disabled}
          content="9"
          onClick={() => activeValueChange(activeValue * 10 + 9)}
        />
        <Button
          disabled={disabled}
          content={activeValue >= 0 ? "-" : "+"}
          onClick={() => activeValueChange(-activeValue)}
        />
        <Button
          disabled={disabled}
          content="0"
          onClick={() => activeValueChange(activeValue * 10)}
        />
        <Button
          disabled={disabled}
          content="<-"
          onClick={() =>
            activeValueChange(
              activeValue >= 0
                ? Math.floor(activeValue / 10)
                : Math.ceil(activeValue / 10),
            )
          }
        />
      </Grid>
      <SpacedDivider space={25} />
      <Grid container spacing={3}>
        <Button
          disabled={disabled}
          active={bulk}
          content="En vrac"
          onClick={() => bulkChange(!bulk)}
        />
        <Grid item xs={4}></Grid>
        {substractive ? (
          <Button
            disabled={disabled}
            content="Passer normal"
            onClick={() => substractiveChange(false)}
          />
        ) : (
          <Button
            disabled={disabled}
            content="Mettre à 0"
            onClick={() => valueChange(0)}
          />
        )}
      </Grid>
    </div>
  );
};
