import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { humanReadableDate } from "@bjelos-farm/lib-common-tools";

type OrderSummary = { date: Date; value: number };

const styles = StyleSheet.create({
  firstRow: {
    padding: 2,
    flexDirection: "row",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    fontWeight: "semibold",
    fontSize: 10,
  },
  col1: {
    paddingLeft: 10,
    width: "33%",
    textAlign: "left",
    marginRight: 28,
  },
  col2: {
    width: "25%",
    textAlign: "right",
  },
  col3: {
    width: "15%",
    textAlign: "right",
  },
  col4: {
    width: "15%",
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#D2D2D2",
    padding: 2,
  },
  lastRow: {
    flexDirection: "row",
    width: "100%",
    padding: 2,
  },
});

interface Props {
  values: OrderSummary[];
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

export const priceFormatter3 = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export const formatPrice3 = (input: number) =>
  priceFormatter3.format(input / 100);

export const Table: React.FC<Props> = ({ values }) => {
  return (
    <View>
      <View style={styles.firstRow}>
        <Text style={styles.col1}>Date</Text>
        <Text style={styles.col2}>Prix HT</Text>
        <Text style={styles.col3}>TVA (2.5%)</Text>
        <Text style={styles.col4}>Prix TTC</Text>
      </View>
      {values
        .sort((val1, val2) => val1.date.valueOf() - val2.date.valueOf())
        .map((item, index) => {
          return (
            <View style={styles.row} key={index}>
              <Text style={styles.col1}>
                Livraison du {humanReadableDate(item.date).slice(8)}
              </Text>
              <Text style={styles.col2}>{formatPrice2(item.value)}</Text>
              <Text style={styles.col3}>
                {formatPrice2(item.value * 0.025)}
              </Text>
              <Text style={styles.col4}>
                {formatPrice2(item.value * 1.025)}
              </Text>
            </View>
          );
        })}
    </View>
  );
};
