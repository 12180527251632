import { Card, Grid, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  label: string;
  onClick: () => void;
}

export const ToAddCard: React.FC<Props> = ({ label, onClick }) => {
  return (
    <Grid item md={4} xs={6} onClick={onClick}>
      <Card
        style={{
          height: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          WebkitUserSelect: "none",
        }}
        elevation={2}
      >
        <Typography variant="h5" color="primary">
          {label}
        </Typography>
      </Card>
    </Grid>
  );
};
