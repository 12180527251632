import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { hasRotation, SpacedDivider } from "@bjelos-farm/lib-client-common";
import { ExpandMore } from "@material-ui/icons";

type VariantDetail = { for: string; qty: number };
type VariantData = { sum: number; details: VariantDetail[] };

export type ProductSums = {
  sum: number;
  printBarcode: VariantData;
  printDate: VariantData;
  stickerGreen: VariantData;
  noStickers: VariantData;
  none: VariantData;
  bulk: VariantData;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
}));

interface Props {
  name: string;
  sums: ProductSums;
  expanded: boolean;
  toggleExpanded: () => void;
}

interface VariantProps {
  label: string;
  data: VariantData;
  expanded: boolean;
}

const Variant: React.FC<VariantProps> = ({ label, data, expanded }) => {
  if (data.sum <= 0) return <></>;
  return (
    <>
      {expanded && <SpacedDivider space={5} />}
      <Typography variant={expanded ? "h4" : "body2"}>
        {label} : {data.sum}
      </Typography>

      {expanded &&
        data.details.map((detail, index) => (
          <Typography
            variant="body2"
            key={index}
          >{`${detail.qty} pour ${detail.for}`}</Typography>
        ))}
    </>
  );
};

export const Sums: React.FC<Props> = ({
  name,
  sums,
  expanded,
  toggleExpanded,
}) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} md={4} xs={12}>
      <Card className={classes.root}>
        <CardHeader
          title={name}
          action={
            <IconButton
              className={expanded ? classes.expandOpen : classes.expand}
              onClick={() => toggleExpanded()}
            >
              <ExpandMore />
            </IconButton>
          }
        />
        <CardContent>
          {sums.sum > 0 && (
            <Typography variant="body2"> Total : {sums.sum} </Typography>
          )}
          <Variant
            label="Avec code barre"
            data={sums.printBarcode}
            expanded={expanded}
          />
          <Variant
            label="Avec date"
            data={sums.printDate}
            expanded={expanded}
          />
          <Variant
            label="Avec étiquette verte"
            data={sums.stickerGreen}
            expanded={expanded}
          />
          <Variant
            label="Sans étiquette"
            data={sums.noStickers}
            expanded={expanded}
          />
          <Variant label="Sans rien" data={sums.none} expanded={expanded} />
          <Variant label="En vrac" data={sums.bulk} expanded={expanded} />
        </CardContent>
        <Box flexGrow={1} />
      </Card>
    </Grid>
  );
};
