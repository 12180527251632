import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "@bjelos-farm/lib-client-assets/assets/invoicelogo.png";
import { OrderListFragment } from "../../Generated/graphql";
import { invoiceDate } from "@bjelos-farm/lib-common-tools";

const styles = StyleSheet.create({
  sideBySide: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  image: {
    width: 100,
  },
});

interface Props {
  order: OrderListFragment;
  date: Date;
}

export const Header: React.FC<Props> = ({ order, date }) => {
  const firmName =
    order.location.name === order.location.firm.name
      ? order.location.name
      : `${order.location.firm.name} ${order.location.name}`;
  return (
    <View>
      <View style={styles.sideBySide}>
        <View>
          <Text>Gala+Fabien Berlie</Text>
          <Text>Fromage de chèvre fermier</Text>
          <Image style={styles.image} src={logo} />
        </View>
        <View>
          <Text>1170 Aubonne</Text>
          <Text>La Croix-de-Luisant 21</Text>
          <Text>078 847 11 74</Text>
          <Text>079 696 12 38</Text>
          <Text>021 565 73 00</Text>
          <Text>aubonne@fromagedechevre.ch</Text>
          <Text>IBAN CH36 0832 7765 0594 3467 3</Text>
          <Text>CH-BIO 006</Text>
        </View>
      </View>
      <Text style={{ marginLeft: "auto" }}>
        {order.location.monthly ? "Livraison du : " : "Facture du : "}
        {invoiceDate(date)}
      </Text>
      <Text style={{ marginLeft: "auto", marginRight: "auto", fontSize: 18 }}>
        {firmName}
      </Text>
    </View>
  );
};
