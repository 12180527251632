import React from "react";
import { Document } from "@react-pdf/renderer";
import { OrderInLocationShowFragment } from "../../Generated/graphql";
import { InvoicePage } from "./InvoicePage";

interface Props {
  order: OrderInLocationShowFragment;
  date: Date;
  title: string;
  firmName: string;
  externalRef: string;
}

export const NewManor: React.FC<Props> = ({
  order,
  title,
  date,
  firmName,
  externalRef,
}) => {
  return (
    <Document title={title}>
      <InvoicePage
        order={order}
        date={date}
        firmName={firmName}
        externalRef={externalRef}
      />
    </Document>
  );
};
