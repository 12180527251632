import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ExposureNeg1, PlusOne } from "@material-ui/icons";
import { getDayEU, textFromDay } from "@bjelos-farm/lib-common-tools";
import { MyDatePicker } from "@bjelos-farm/lib-client-common";
import { MyCard } from "../../Components/Schedule/MyCard";
import { useCallSchedulesLazyQuery } from "../../Generated/graphql";

export const Schedule: React.FC = () => {
  const [query, { data, loading }] = useCallSchedulesLazyQuery();
  const [getDate, setDate] = React.useState<Date>(new Date());

  const callSchedules = (data?.callSchedules || []).filter(
    (callSchedule) => !callSchedule.location.disabled,
  );

  const day = getDayEU(getDate);

  React.useEffect(() => {
    const minDate = new Date(getDate.valueOf());
    minDate.setDate(getDate.getDate() - 3);
    query({
      variables: {
        where: {
          day: { equals: day },
          calls: {
            none: {
              AND: [
                { concluent: { equals: true } },
                { createdAt: { gt: minDate } },
              ],
            },
          },
        },
      },
    });
  }, [getDate, day, query]);

  if (loading) {
    return <p>Chargement</p>;
  }
  return (
    <>
      <Typography variant="h2">Jours: {textFromDay(day)}</Typography>
      <IconButton
        onClick={() => {
          const tempDate = new Date(getDate.valueOf());
          tempDate.setDate(getDate.getDate() - 1);
          setDate(tempDate);
        }}
      >
        <ExposureNeg1 color="primary" />
      </IconButton>
      <MyDatePicker value={getDate} onChange={setDate} />
      <IconButton
        onClick={() => {
          const tempDate = new Date(getDate.valueOf());
          tempDate.setDate(getDate.getDate() + 1);
          setDate(tempDate);
        }}
      >
        <PlusOne color="primary" />
      </IconButton>
      <Grid container spacing={3}>
        {callSchedules.map((callSchedule, index) => (
          <MyCard data={callSchedule} key={index} />
        ))}
      </Grid>
    </>
  );
};
