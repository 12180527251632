import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { OrderInLocationShowFragment } from "../../Generated/graphql";
import { invoiceDate } from "@bjelos-farm/lib-common-tools";

interface Props {
  order: OrderInLocationShowFragment;
  date: Date;
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

const styles = StyleSheet.create({
  root: { marginTop: "auto", height: 100 },
  sideBySide: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  date: {
    fontWeight: "semibold",
    fontSize: 12,
  },
  total: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5 10 5 10",
    marginBottom: 4,
    width: 240,
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    fontWeight: "semibold",
    fontSize: 10,
  },
  superTotal: {
    color: "white",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5 10 5 10",
    marginBottom: 5,
    width: 240,
    borderRadius: 10,
    backgroundColor: "#B15B10",
    fontWeight: "semibold",
    fontSize: 10,
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  underlined: {
    textDecoration: "underline",
    paddingRight: 2,
  },
  greytext: {
    marginTop: 10,
    fontSize: 8,
    color: "#8E8E8E",
    fontWeight: "semibold",
  },
});

export const Footer: React.FC<Props> = ({ order, date }) => {
  const total =
    order.cart.cartItems?.reduce<number>(
      (previous, current) =>
        previous + current.productPrice.price * current.qty,
      0,
    ) || 0;
  return (
    <View style={styles.root}>
      <View style={styles.sideBySide}>
        <View>
          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
            <Text style={{ fontWeight: "bold" }}>IBAN : </Text>
            <Text>CH36 0832 7765 0594 3467 3</Text>
          </View>
          <Text>CHE.358.221.637 TVA</Text>
          <Text>CH-BIO 006</Text>
          <View style={styles.greytext}>
            <Text>
              Émise le {invoiceDate(new Date()).replaceAll("/", ".")}, payable
              sous 30 jours.
            </Text>
            <Text>
              Nous vous remercions de votre confiance et de votre règlement.
            </Text>
            <Text>Avec nos meilleurs salutations, Gala et Fabien</Text>
          </View>
        </View>
        <View>
          <View style={styles.total}>
            <Text>Montant HT</Text>
            <Text>{formatPrice2(total)}</Text>
          </View>
          <View style={styles.total}>
            <Text>TVA (2,5%)</Text>
            <Text>{formatPrice2(total * 0.025)}</Text>
          </View>
          <View style={styles.superTotal}>
            <Text>Total (CHF):</Text>
            <Text>{formatPrice2(total * 1.025)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
