import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardActionArea,
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { StyledLink } from "@bjelos-farm/lib-client-common";
import { CallScheduleListFragment } from "../../Generated/graphql";
import {
  humanReadableTimeEpoch,
  textFromDay,
} from "@bjelos-farm/lib-common-tools";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.error.main,
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  data: CallScheduleListFragment;
}

export const MyCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} md={4} xs={12}>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {data.location.firm.name === data.location.name
              ? `${data.location.name}`
              : `${data.location.firm.name} - ${data.location.name}`}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
          >
            Livraison {textFromDay(data.deliveryDayAddition + data.day)}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
          >
            à {humanReadableTimeEpoch(data.deliveryTime)}
          </Typography>
          <pre>{data.comment}</pre>
        </CardContent>
        <Box flexGrow={1} />
        <Divider />
        <StyledLink to={`/schedule/${data.id}/call`}>
          <CardActionArea>
            <Box p={2}>
              <Grid container justify="center" spacing={2}>
                <Grid className={classes.statsItem} item>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    Appeler
                  </Typography>
                  <Launch className={classes.statsIcon} color="action" />
                </Grid>
              </Grid>
            </Box>
          </CardActionArea>
        </StyledLink>
      </Card>
    </Grid>
  );
};
