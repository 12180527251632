import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "@bjelos-farm/lib-client-assets/assets/invoicelogonew.png";
import { GetLocationQuery } from "../../Generated/graphql";

const height = 118;

const styles = StyleSheet.create({
  root: {
    fontSize: 11,
    color: "#151515",
    marginBottom: 5,
  },
  sideBySide: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  right: { alignItems: "flex-end", marginTop: 15 },
  date: { fontWeight: "bold", fontSize: 12 },
  type: { fontWeight: "bold", fontSize: 24 },
  bold: { fontWeight: "semibold" },
  location: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  locationSmaller: {
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: "auto",
    marginRight: "auto",
  },
  image: {
    height: height,
    width: height,
  },
  underlined: {
    textDecoration: "underline",
  },
});

interface Props {
  location: GetLocationQuery;
}

export const Header: React.FC<Props> = ({ location: locationPack }) => {
  if (!locationPack.location) return <View></View>;
  const location = locationPack.location;
  const firmName =
    location.name === location.firm.name
      ? location.name
      : `${location.firm.name} ${location.name}`;
  return (
    <View style={styles.root}>
      <View style={styles.sideBySide}>
        <View>
          <Image style={styles.image} src={logo} />
          <Text style={styles.bold}>Gala + Fabien Berlie</Text>
          <Text>Fromage de chèvre fermier</Text>
          {/* <Text>CH-BIO 006</Text> */}
          <Text>La Croix-de-Luisant 21, 1170 Aubonne</Text>
          <Text>078 847 11 74 - 079 696 12 38 - 021 565 73 00</Text>
          <Text style={styles.underlined}>aubonne@fromagedechevre.ch</Text>
        </View>
        <View style={styles.right}>
          <Text style={styles.date}></Text>
          <Text style={styles.type}>FACTURE</Text>
          <View style={{ paddingTop: 50 }}>
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>{firmName}</Text>
            {location.billingAddress ? (
              <Text style={{ marginLeft: "auto" }}>
                {location.billingAddress}
              </Text>
            ) : (
              <Text style={{ marginLeft: "auto" }}>{location.address}</Text>
            )}
          </View>
        </View>
      </View>
      <Text style={{ marginTop: 10, marginBottom: 10 }}>
        <Text style={{ fontWeight: "bold" }}>Objet:</Text> Récapitulatif de la
        TVA 2.5% du 01.01.2021 au 31.08.2021 (inclus)
      </Text>
    </View>
  );
};
