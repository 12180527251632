import React, { SetStateAction } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  SpacedDivider,
  OrderMethod,
  MyDateTimePicker,
} from "@bjelos-farm/lib-client-common";
import { getNextDayAndTime } from "@bjelos-farm/lib-common-tools";
import {
  LocationType,
  ProductFragment,
  useCallScheduleQuery,
  useNonConcluentCallMutation,
  useNoOrderCallMutation,
  useOrderCallMutation,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { ActiveCard } from "../../Components/Call/ActiveCard";
import { ToAddCard } from "../../Components/Call/ToAddCard";
import { Numpad } from "../../Components/Call/Numpad";

type ProductPricePossibilitesType = { name: string; id: number };
type OrderElementType = {
  qty: number;
  bulk: boolean;
  productPriceId: number;
  substractive: boolean;
};

const getPricePossibilites = (
  products: ProductFragment[],
  locationType: LocationType,
): ProductPricePossibilitesType[] =>
  products.reduce<ProductPricePossibilitesType[]>((prev, current) => {
    const id = current.productPrices?.find(
      (price) => price.locationType === locationType,
    )?.id;
    return id !== undefined && !current.disabled
      ? [
          ...prev,
          {
            id,
            name: current.category?.name
              ? `${current.category.name} - ${current.name}`
              : current.name,
          },
        ]
      : prev;
  }, []);

export const Create: React.FC = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [getRedirect, setRedirect] = React.useState<boolean>(false);

  const [getFullScreen, setFullScreen] = React.useState<Boolean>(true);
  const [getDate, setDate] = React.useState<Date>(new Date());
  const [getOrders, setOrders] = React.useState<OrderElementType[]>([]);
  const [getActiveId, setActiveIdBare] = React.useState<number | undefined>();
  const [getSubstraction, setSubstraction] = React.useState<number>(0);

  React.useEffect(() => {
    document.body.style.overscrollBehaviorY = "contain";
    return () => {
      document.body.style.overscrollBehaviorY = "auto";
    };
  }, []);

  const getModifiedOrders = () => {
    if (getSubstraction !== 0) {
      const currentOrderIndex = getOrders.findIndex(
        (order) => order.productPriceId === getActiveId,
      );
      if (currentOrderIndex !== -1) {
        const tempOrders = JSON.parse(
          JSON.stringify(getOrders),
        ) as OrderElementType[];
        tempOrders[currentOrderIndex].substractive = false;
        tempOrders[currentOrderIndex].qty =
          tempOrders[currentOrderIndex].qty - getSubstraction;
        return tempOrders;
      }
    }
    return getOrders;
  };

  const apply = () => {
    if (getSubstraction !== 0) {
      setOrders(getModifiedOrders());
    }
    setSubstraction(0);
  };

  const setActiveId = (value: SetStateAction<number | undefined>): void => {
    apply();
    setActiveIdBare(value);
  };

  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { products, refetch } = useData();
  const { data, loading } = useCallScheduleQuery({
    variables: { where: { id: numId } },
    onCompleted: (data) => {
      if (data.callSchedule) {
        const fullStockItems = (
          data.callSchedule.location.fullStock.cartItems || []
        )?.filter((item) => !item.productPrice.product.disabled);
        setDate(
          getNextDayAndTime(
            data.callSchedule.day + data.callSchedule.deliveryDayAddition,
            data.callSchedule.deliveryTime,
          ),
        );
        if (data.callSchedule.location.orderMethod === OrderMethod.Normal) {
          setOrders(
            fullStockItems.map((cartItem) => ({
              qty: 0,
              bulk: cartItem.bulk,
              productPriceId: cartItem.productPrice.id,
              substractive: false,
            })),
          );
        } else if (
          data.callSchedule.location.orderMethod === OrderMethod.FromBasis
        ) {
          setOrders(
            fullStockItems.map((cartItem) => ({
              qty: cartItem.qty,
              bulk: cartItem.bulk,
              productPriceId: cartItem.productPrice.id,
              substractive: true,
            })),
          );
        } else {
          setOrders(
            fullStockItems.map((cartItem) => ({
              qty: cartItem.qty,
              bulk: cartItem.bulk,
              productPriceId: cartItem.productPrice.id,
              substractive: false,
            })),
          );
        }
      }
    },
  });

  const [noOrder] = useNoOrderCallMutation({
    variables: { where: { id: numId } },
    update: () => setRedirect(true),
  });

  const [noConcluent] = useNonConcluentCallMutation({
    variables: { where: { id: numId } },
    update: () => setRedirect(true),
  });

  const [createOrder] = useOrderCallMutation({
    update: () => setRedirect(true),
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const callSchedule = data?.callSchedule;

  const defaultStock = !callSchedule
    ? []
    : callSchedule.location.fullStock.cartItems?.map((cartItem) => ({
        qty: cartItem.qty,
        bulk: cartItem.bulk,
        productPriceId: cartItem.productPrice.id,
      })) || [];

  const pricePossibilites = !callSchedule
    ? []
    : getPricePossibilites(products, callSchedule.location.locationType).map(
        ({ id, name }) => {
          const qty = defaultStock.find(
            ({ productPriceId }) => productPriceId === id,
          )?.qty;
          return {
            id,
            name: qty ? `${name} (${qty})` : name,
          };
        },
      );

  if (getRedirect) return <Redirect to="/schedule" />;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!callSchedule) {
    return <p>Element non trouvé</p>;
  }

  return (
    <Card
      style={
        getFullScreen
          ? {
              position: "fixed",
              background: "white",
              zIndex: 9999,
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
            }
          : {}
      }
    >
      <CardActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ height: "10vh" }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setFullScreen(!getFullScreen)}
          >
            {getFullScreen ? "Sortir du pleine écran" : "Pleine écran"}
          </Button>
          <Button color="primary" variant="outlined" onClick={() => noOrder()}>
            Pas de commande
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => noConcluent()}
          >
            Appel non concluant
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              const modifiedOrders = getModifiedOrders();
              createOrder({
                variables: {
                  where: { id: numId },
                  orderCreate: {
                    location: { connect: { id: callSchedule.location.id } },
                    when: getDate,
                    cart: {
                      create: {
                        cartItems: {
                          create: modifiedOrders
                            .filter((order) => order.qty > 0)
                            .map(({ bulk, qty, productPriceId }) => ({
                              qty,
                              bulk,
                              productPrice: { connect: { id: productPriceId } },
                            })),
                        },
                      },
                    },
                  },
                },
              });
            }}
          >
            Créer une commande
          </Button>
        </Grid>
      </CardActions>
      <CardContent>
        <Grid
          container
          spacing={3}
          direction={largeScreen ? "row" : "column-reverse"}
        >
          <Grid
            item
            md={8}
            xs={12}
            style={{ height: "90vh", overflowY: "scroll" }}
          >
            <Grid container spacing={3}>
              {getOrders.map(({ bulk, qty, productPriceId }, index) => (
                <ActiveCard
                  key={index}
                  bulk={bulk}
                  qty={qty}
                  substraction={getSubstraction}
                  label={
                    pricePossibilites.find(({ id }) => productPriceId === id)
                      ?.name || "Nom introuvable"
                  }
                  active={productPriceId === getActiveId}
                  setActive={() => setActiveId(productPriceId)}
                />
              ))}
            </Grid>
            <SpacedDivider space={30} />
            <Typography variant="h4" gutterBottom>
              Ajouter à la commande
            </Typography>
            <Grid container spacing={3}>
              {pricePossibilites
                .filter(
                  (possibility) =>
                    getOrders.findIndex(
                      (order) => order.productPriceId === possibility.id,
                    ) === -1,
                )
                .map(({ id, name }, index) => (
                  <ToAddCard
                    key={index}
                    label={name}
                    onClick={() => {
                      setOrders([
                        ...getOrders,
                        {
                          bulk: false,
                          productPriceId: id,
                          qty: 0,
                          substractive: false,
                        },
                      ]);
                      setActiveId(id);
                    }}
                  />
                ))}
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <MyDateTimePicker
              value={getDate}
              onChange={(value) => setDate(value)}
              label="Date de livraison"
            />
            <SpacedDivider space={5} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography>
                  {callSchedule.location.firm.name ===
                  callSchedule.location.name
                    ? `${callSchedule.location.name}`
                    : `${callSchedule.location.firm.name} - ${callSchedule.location.name}`}
                </Typography>
                <Typography>
                  {callSchedule.location.phones?.join(" - ")}
                </Typography>
                {callSchedule.comment && (
                  <Typography variant="body2">
                    {callSchedule.comment}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                {callSchedule.location.orders &&
                  callSchedule.location.orders[0] &&
                  callSchedule.location.orders[0].cart.cartItems?.map(
                    (cartItem, index) => (
                      <Typography variant="h6" key={index}>
                        {cartItem.productPrice.product.category?.name &&
                          `${cartItem.productPrice.product.category?.name} `}
                        {cartItem.productPrice.product.name} - {cartItem.qty}
                      </Typography>
                    ),
                  )}
              </Grid>
            </Grid>
            <SpacedDivider space={20} />
            <Numpad
              substraction={getSubstraction}
              substractionChange={(value) => setSubstraction(value)}
              substractive={
                getOrders.find((order) => order.productPriceId === getActiveId)
                  ?.substractive || false
              }
              substractiveChange={(substractive) => {
                const currentOrderIndex = getOrders.findIndex(
                  (order) => order.productPriceId === getActiveId,
                );
                if (currentOrderIndex !== -1) {
                  const tempOrders = JSON.parse(
                    JSON.stringify(getOrders),
                  ) as OrderElementType[];
                  tempOrders[currentOrderIndex].substractive = substractive;
                  setOrders(tempOrders);
                }
              }}
              disabled={!getActiveId}
              value={
                getOrders.find((order) => order.productPriceId === getActiveId)
                  ?.qty || 0
              }
              valueChange={(value) => {
                const currentOrderIndex = getOrders.findIndex(
                  (order) => order.productPriceId === getActiveId,
                );
                if (currentOrderIndex !== -1) {
                  const tempOrders = JSON.parse(
                    JSON.stringify(getOrders),
                  ) as OrderElementType[];
                  tempOrders[currentOrderIndex].qty = value;
                  setOrders(tempOrders);
                }
              }}
              bulk={
                getOrders.find((order) => order.productPriceId === getActiveId)
                  ?.bulk || false
              }
              bulkChange={(bulk) => {
                const currentOrderIndex = getOrders.findIndex(
                  (order) => order.productPriceId === getActiveId,
                );
                if (currentOrderIndex !== -1) {
                  const tempOrders = JSON.parse(
                    JSON.stringify(getOrders),
                  ) as OrderElementType[];
                  tempOrders[currentOrderIndex].bulk = bulk;
                  setOrders(tempOrders);
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
