import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { OrderInLocationShowFragment } from "../../Generated/graphql";

const styles = StyleSheet.create({
  firstRow: {
    padding: 2,
    flexDirection: "row",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#D2D2D2",
    fontWeight: "semibold",
    fontSize: 10,
  },
  col1: {
    paddingLeft: 10,
    width: "10%",
    textAlign: "center",
    marginRight: 28,
  },
  col2: {
    width: "46%",
  },
  col3: {
    width: "16%",
    textAlign: "right",
  },
  col4: {
    width: "16%",
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#D2D2D2",
    padding: 2,
  },
  lastRow: {
    flexDirection: "row",
    width: "100%",
    padding: 2,
  },
});

interface Props {
  order: OrderInLocationShowFragment;
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

export const priceFormatter3 = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export const formatPrice3 = (input: number) =>
  priceFormatter3.format(input / 100);

type Product = {
  qty: number;
  name: string;
  price: number;
  order: number;
};

export const Table: React.FC<Props> = ({ order }) => {
  const products: Product[] = (order.cart.cartItems || [])
    .map((item) => ({
      name: item.productPrice.product.category
        ? `${item.productPrice.product.category.name} ${item.productPrice.product.name}`
        : item.productPrice.product.name,
      order:
        item.productPrice.product.order +
        100 * (item.productPrice.product.category?.order ?? 100),
      price: item.productPrice.price,
      qty: item.qty,
    }))
    .sort((product1, product2) => product1.order - product2.order);
  return (
    <View>
      <View style={styles.firstRow}>
        <Text style={styles.col1}>Qté</Text>
        <Text style={styles.col2}>Désignation</Text>
        <Text style={styles.col3}>Prix unité</Text>
        <Text style={styles.col4}>Prix</Text>
      </View>
      {products.map((item, index) => {
        return (
          <View
            style={
              index === 15 || (index === 14 && products.length === 15)
                ? styles.lastRow
                : styles.row
            }
            key={index}
          >
            <Text style={styles.col1}>{item.qty}</Text>
            <Text style={styles.col2}>{item.name}</Text>
            <Text style={styles.col3}>{formatPrice3(item.price)}</Text>
            <Text style={styles.col4}>
              {formatPrice2(item.price * item.qty)}
            </Text>
          </View>
        );
      })}
    </View>
  );
};
