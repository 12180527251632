import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import MaterialTable from "material-table";
import { SpacedDivider, formatPrice } from "@bjelos-farm/lib-client-common";
import {
  OrderStatus,
  useGetLocationQuery,
  useSetOrderDeliveredMutation,
  useOrderEditMutation,
} from "../../Generated/graphql";
import { humanReadableDate, fileNameDate } from "@bjelos-farm/lib-common-tools";
import { PdfDownloadButton } from "../../Components/PdfDownloadButton";
import { TVARecap } from "../../Pdf/TVARecap";
import { NewManor } from "../../Pdf/NewManor";
import { SemestrialRecap } from "../../Pdf/SemestrialRecap";
import { slufigy } from "../../Utils/slugify";

export const Location: React.FC = () => {
  const [getOpen, setOpen] = React.useState<
    | {
        id: number;
        externalRef: string;
      }
    | undefined
  >(undefined);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { data, loading, refetch } = useGetLocationQuery({
    variables: { id: numId },
  });
  const [setDelivered] = useSetOrderDeliveredMutation({
    update: () => refetch && refetch(),
  });

  const [orderEdit] = useOrderEditMutation({
    update: () => {
      setOpen(undefined);
      refetch && refetch();
    },
  });

  const pageData = data?.location;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData) {
    return <p>Element non trouvé</p>;
  }

  const firmName =
    pageData.name === pageData.firm.name
      ? pageData.name
      : `${pageData.firm.name} - ${pageData.name}`;

  const filename = `${slufigy(firmName)}-recapitulatif-tva.pdf`;

  const filenameOther = `${slufigy(firmName)}-recapitulatif-semestriel.pdf`;
  const isManor = pageData.firm.id === 1;
  return (
    <Card>
      <CardHeader title={firmName} />
      <SpacedDivider />
      {data && (
        <PdfDownloadButton
          showfilename
          filename={filename}
          document={<TVARecap location={data} title={filename} />}
        />
      )}
      {data && (
        <PdfDownloadButton
          showfilename
          filename={filenameOther}
          document={<SemestrialRecap location={data} title={filenameOther} />}
        />
      )}
      <CardContent>
        <MaterialTable
          title="Commandes"
          data={pageData.orders || []}
          options={{ filtering: true, pageSize: 10 }}
          detailPanel={(rowData) => {
            return (
              <div>
                {(rowData.cart.cartItems || []).map((item, index) => (
                  <p key={index}>
                    {item.qty} *
                    {item.productPrice.product.category
                      ? ` ${item.productPrice.product.category.name} - `
                      : ""}
                    {` ${item.productPrice.product.name}`}
                    {` (${formatPrice(item.productPrice.price)})`}
                    {` = ${formatPrice(item.productPrice.price * item.qty)}`}
                  </p>
                ))}
                <p>
                  Total =
                  {` ${formatPrice(
                    (rowData.cart.cartItems || []).reduce<number>(
                      (prev, item) => {
                        return prev + item.productPrice.price * item.qty;
                      },
                      0,
                    ),
                  )}`}
                </p>
                {rowData.status === OrderStatus.Paid && (
                  <Button
                    onClick={() => {
                      orderEdit({
                        variables: {
                          id: rowData.id,
                          data: {
                            status: {
                              set: OrderStatus.Active,
                            },
                          },
                        },
                      });
                    }}
                    color="primary"
                  >
                    Noter comme non payé
                  </Button>
                )}
                {rowData.status === OrderStatus.Active && (
                  <Button
                    onClick={() => {
                      orderEdit({
                        variables: {
                          id: rowData.id,
                          data: {
                            status: {
                              set: OrderStatus.Paid,
                            },
                          },
                        },
                      });
                    }}
                    color="primary"
                  >
                    Noter comme payé
                  </Button>
                )}
              </div>
            );
          }}
          columns={[
            {
              title: "Livraison prévue",
              field: "when",
              render: ({ when }) => humanReadableDate(new Date(when)),
              filtering: false,
            },
            {
              title: "Livré à",
              field: "when",
              render: ({ deliveredAt }) =>
                deliveredAt
                  ? humanReadableDate(new Date(deliveredAt))
                  : "Non livré",
              filtering: false,
            },
            {
              title: "Status",
              field: "status",
              lookup: {
                CANCELED: "Annulée",
                ACTIVE: "Non payé",
                PAID: "Payé",
              },
              defaultFilter: ["PAID", "ACTIVE"],
            },
            {
              title: "Ref",
              field: "externalRef",
              render: (order) => {
                const { externalRef, id, when } = order;
                const date = new Date(when);
                const filename = `${slufigy(firmName)}-${fileNameDate(
                  date,
                )}.pdf`;

                return (
                  <div style={{ display: "flex" }}>
                    {isManor && date.getMonth() >= 7 && (
                      <PdfDownloadButton
                        filename={filename}
                        document={
                          <NewManor
                            order={order}
                            date={date}
                            title={filename}
                            firmName={firmName}
                            externalRef={externalRef ?? ""}
                          />
                        }
                      />
                    )}
                    {externalRef ? (
                      <div>
                        {externalRef}
                        <IconButton
                          color="inherit"
                          onClick={() => setOpen({ id, externalRef })}
                        >
                          <Edit />
                        </IconButton>
                      </div>
                    ) : (
                      <div>
                        <IconButton
                          color="inherit"
                          onClick={() =>
                            setOpen({ id, externalRef: externalRef || "" })
                          }
                        >
                          <Add />
                        </IconButton>
                      </div>
                    )}
                  </div>
                );
              },
            },
          ]}
          actions={[
            {
              icon: "add",
              tooltip: "Ajouter une commande",
              isFreeAction: true,
              onClick: () => history.push(`${id}/order`),
            },
            (order) => ({
              icon:
                order.status === OrderStatus.Canceled
                  ? "restore_from_trash"
                  : "delete",
              tooltip: "Supprimer",
              onClick: () => {
                orderEdit({
                  variables: {
                    id: order.id,
                    data: {
                      status: {
                        set:
                          order.status === OrderStatus.Active
                            ? OrderStatus.Canceled
                            : OrderStatus.Active,
                      },
                    },
                  },
                });
              },
            }),
            (order) => ({
              icon: "open_in_new",
              tooltip: "Modifier",
              onClick: () => {
                history.push(`/delivery/${order.id}/update`);
              },
            }),
            (order) => ({
              icon: "menu_open",
              tooltip: "Livrer à la date prévue",
              onClick: () => {
                setDelivered({
                  variables: {
                    where: { id: order.id },
                    data: { deliveredAt: { set: new Date(order.when) } },
                  },
                });
              },
              hidden: order.deliveredAt,
            }),
            (order) => ({
              icon: "open_in_browser",
              tooltip: "Livrer maintenant",
              onClick: () => {
                setDelivered({
                  variables: {
                    where: { id: order.id },
                    data: { deliveredAt: { set: new Date() } },
                  },
                });
              },
              hidden: order.deliveredAt,
            }),
          ]}
        />
      </CardContent>
      <Dialog open={getOpen !== undefined} onClose={() => setOpen(undefined)}>
        <DialogTitle>Nouvelle référence</DialogTitle>
        <DialogContent>
          <DialogContentText>Inscrire la nouvelle référence</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Référence"
            fullWidth
            value={getOpen?.externalRef || ""}
            onChange={(value) =>
              setOpen(
                getOpen
                  ? { id: getOpen.id, externalRef: value.target.value }
                  : undefined,
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(undefined)} color="primary">
            Annuler
          </Button>
          <Button
            onClick={() =>
              getOpen &&
              orderEdit({
                variables: {
                  id: getOpen.id,
                  data: { externalRef: { set: getOpen.externalRef } },
                },
              })
            }
            color="primary"
          >
            Appliquer la ref
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
