import React from "react";
import { makeItTwo } from "@bjelos-farm/lib-common-tools";
import { SpacedDivider } from "@bjelos-farm/lib-client-common";
import {
  useMonthlyQuery,
  useUpdateManyOrderMutation,
  OrderStatus,
} from "../Generated/graphql";
import { Button, TextField, Typography } from "@material-ui/core";

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

type MonthElement = {
  ids: number[];
  paidAt?: Date;
  price: number;
  firmName: string;
  month: string;
};

// Get Price from order(s)
type OrderForPrice = {
  cart: {
    cartItems?: { qty: number; productPrice: { price: number } }[] | null;
  };
};

const getPriceFromOrder = (order: OrderForPrice): number =>
  order.cart.cartItems?.reduce<number>(
    (prev, curr) => prev + curr.qty * curr.productPrice.price,
    0,
  ) || 0;

const getPriceFromOrders = (orders: OrderForPrice[]): number =>
  orders.reduce<number>((prev, curr) => prev + getPriceFromOrder(curr), 0);

// Months
const getMinAndMaxDay = (month: number): [Date, Date] => {
  const minday = month <= 3 ? 0 : 1;
  const maxday = month <= 2 ? 0 : 1;
  const minDate = new Date(2021, month, minday, 0, 0, 0, 0);
  const maxDate = new Date(2021, month + 1, maxday, 0, 0, 0, 0);
  return [minDate, maxDate];
};

const monthAndBorders: [string, Date, Date, number][] = [
  ["Janvier", ...getMinAndMaxDay(0), 1],
  ["Février", ...getMinAndMaxDay(1), 1],
  ["Mars", ...getMinAndMaxDay(2), 1],
  ["Avril", ...getMinAndMaxDay(3), 1],
  ["Mai", ...getMinAndMaxDay(4), 1],
  ["Juin", ...getMinAndMaxDay(5), 1],
  ["Juillet", ...getMinAndMaxDay(6), 1],
  ["Août", ...getMinAndMaxDay(7), 1],
  ["Septembre", ...getMinAndMaxDay(8), 1.025],
  ["Octobre", ...getMinAndMaxDay(9), 1.025],
  ["Novembre", ...getMinAndMaxDay(10), 1.025],
  ["Décembre", ...getMinAndMaxDay(11), 1.025],
];

/////////////////////////////////////////////////////////////////////////

export const Monthly: React.FC = () => {
  const [getActive, setActive] = React.useState<string | null>(null);
  const [getToggle, setToggle] = React.useState<boolean>(false);
  const [getView, setSimpleView] = React.useState<"month" | "firm" | "paid">(
    "month",
  );
  const setView = (newView: "month" | "firm" | "paid") => {
    setSimpleView(newView);
    setActive(null);
  };
  const { data, refetch, networkStatus } = useMonthlyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [getPayDate, setPayDate] = React.useState<string>(`2021-01-01`);
  const [updateManyOrderMutation, { loading: mutationLoading }] =
    useUpdateManyOrderMutation({
      onCompleted: () => {
        refetch && refetch();
        setActive(null);
      },
    });

  const loading = networkStatus !== 7 || mutationLoading;

  const locations =
    data?.locations.filter(
      (location) => location.orders && location.orders.length > 0,
    ) || [];

  const firmNames = locations.map((location) =>
    location.name === location.firm.name
      ? location.name
      : `${location.firm.name} ${location.name}`,
  );

  const allMonthElements: MonthElement[] = locations
    .map((location) => {
      const firmName =
        location.name === location.firm.name
          ? location.name
          : `${location.firm.name} ${location.name}`;
      const orders = location.orders ?? [];
      const months: MonthElement[] = monthAndBorders.map((month) => {
        const monthOrders = orders.filter((order) => {
          const when = new Date(order.when);
          return when >= month[1] && when < month[2];
        });
        const ids = monthOrders.map((order) => order.id);
        const price = getPriceFromOrders(monthOrders) * month[3];
        const paidAt = monthOrders[0]?.paidAt;
        return {
          firmName,
          ids,
          month: month[0],
          price,
          paidAt: paidAt ? new Date(paidAt) : undefined,
        };
      });

      return months;
    })
    .flat()
    .filter((monthElement) => monthElement.price !== 0);

  const consideredPayed = (id: number[]) => {
    if (loading) {
      alert("Action impossible, chargement en cours de la dernière action");
      return;
    }
    const paidAt = new Date(`${getPayDate} 12:00`).toISOString();
    updateManyOrderMutation({
      variables: {
        data: {
          status: { set: OrderStatus.Paid },
          paidAt: { set: paidAt },
        },
        where: { id: { in: id } },
      },
    });
  };

  const unpay = (id: number[]) => {
    updateManyOrderMutation({
      variables: {
        data: {
          status: { set: OrderStatus.Active },
          paidAt: { set: null },
        },
        where: { id: { in: id } },
      },
    });
  };

  const unpaidMonthElements = allMonthElements.filter(
    (monthElement) => !monthElement.paidAt,
  );
  const unpaidSum = unpaidMonthElements.reduce<number>(
    (prev, curr) => prev + curr.price,
    0,
  );
  if (getView === "month") {
    return (
      <>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setView("firm")}
        >
          Vue firme
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setView("paid")}
        >
          Vue compte
        </Button>
        <Typography variant="h1">
          Vue par mois - Impayé : {formatPrice2(unpaidSum)}
        </Typography>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setToggle(!getToggle)}
        >
          {getToggle
            ? "Cacher les éléments payés"
            : "Montrer les elements payé"}
        </Button>
        <SpacedDivider space={40} />
        {monthAndBorders.map((month, upperIdx) => {
          const groupedByMonth = (
            getToggle ? allMonthElements : unpaidMonthElements
          ).filter((monthElement) => monthElement.month === month[0]);
          return (
            <div key={upperIdx}>
              <Typography variant="h3">
                {month[0]} - Impayé :
                {formatPrice2(
                  groupedByMonth.reduce<number>(
                    (prev, curr) => prev + (curr.paidAt ? 0 : curr.price),
                    0,
                  ),
                )}
              </Typography>
              {groupedByMonth.map((monthElement, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <SpacedDivider space={2} />
                  <div style={{ display: "flex", width: "90%" }}>
                    <div style={{ width: "20%" }}>{monthElement.firmName}</div>
                    <div style={{ width: "10%", textAlign: "right" }}>
                      {formatPrice2(monthElement.price)
                        .replace(".", "'")
                        .replace(",", ".")}
                    </div>
                    <div
                      style={{
                        width: "30%",
                        textAlign: "right",
                      }}
                    >
                      {monthElement.paidAt ? (
                        `${makeItTwo(
                          monthElement.paidAt.getDate(),
                        )}/${makeItTwo(
                          monthElement.paidAt.getMonth() + 1,
                        )}/${monthElement.paidAt.getFullYear()}`
                      ) : (
                        <Button
                          disabled={loading}
                          variant="contained"
                          onClick={() =>
                            `${upperIdx} - ${index}` === getActive
                              ? setActive(null)
                              : setActive(`${upperIdx} - ${index}`)
                          }
                        >
                          {`${upperIdx} - ${index}` === getActive
                            ? "Annuler"
                            : "Paiement"}
                        </Button>
                      )}
                    </div>
                    {getActive === `${upperIdx} - ${index}` && (
                      <>
                        <div style={{ width: "20%" }}>
                          <TextField
                            label="Date de paiement"
                            type="date"
                            onChange={(value) => setPayDate(value.target.value)}
                            value={getPayDate}
                          />
                        </div>
                        <Button
                          disabled={loading}
                          variant="contained"
                          onClick={() => consideredPayed(monthElement.ids)}
                        >
                          Payer
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <SpacedDivider space={40} />
            </div>
          );
        })}
      </>
    );
  }
  if (getView === "firm") {
    return (
      <>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setView("month")}
        >
          Vue mois
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setView("paid")}
        >
          Vue compte
        </Button>
        <Typography variant="h1">
          Vue par firme - Impayé : {formatPrice2(unpaidSum)}
        </Typography>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setToggle(!getToggle)}
        >
          {getToggle
            ? "Cacher les éléments payés"
            : "Montrer les elements payé"}
        </Button>
        <SpacedDivider space={40} />
        {firmNames.map((firmName, upperIdx) => {
          const groupedByFirm = (
            getToggle ? allMonthElements : unpaidMonthElements
          ).filter((monthElement) => monthElement.firmName === firmName);
          return (
            <div key={upperIdx}>
              <Typography variant="h3">
                {firmName} - Impayé :
                {formatPrice2(
                  groupedByFirm.reduce<number>(
                    (prev, curr) => prev + (curr.paidAt ? 0 : curr.price),
                    0,
                  ),
                )}
              </Typography>
              {groupedByFirm.map((monthElement, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <SpacedDivider space={2} />
                  <div style={{ display: "flex", width: "90%" }}>
                    <div style={{ width: "20%" }}>{monthElement.month}</div>
                    <div style={{ width: "10%", textAlign: "right" }}>
                      {formatPrice2(monthElement.price)
                        .replace(".", "'")
                        .replace(",", ".")}
                    </div>
                    <div
                      style={{
                        width: "30%",
                        textAlign: "right",
                      }}
                    >
                      {monthElement.paidAt ? (
                        `${makeItTwo(
                          monthElement.paidAt.getDate(),
                        )}/${makeItTwo(
                          monthElement.paidAt.getMonth() + 1,
                        )}/${monthElement.paidAt.getFullYear()}`
                      ) : (
                        <Button
                          disabled={loading}
                          variant="contained"
                          onClick={() =>
                            `${upperIdx} - ${index}` === getActive
                              ? setActive(null)
                              : setActive(`${upperIdx} - ${index}`)
                          }
                        >
                          {`${upperIdx} - ${index}` === getActive
                            ? "Annuler"
                            : "Paiement"}
                        </Button>
                      )}
                    </div>
                    {getActive === `${upperIdx} - ${index}` && (
                      <>
                        <div style={{ width: "20%" }}>
                          <TextField
                            label="Date de paiement"
                            type="date"
                            onChange={(value) => setPayDate(value.target.value)}
                            value={getPayDate}
                          />
                        </div>
                        <Button
                          disabled={loading}
                          variant="contained"
                          onClick={() => consideredPayed(monthElement.ids)}
                        >
                          Payer
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <SpacedDivider space={40} />
            </div>
          );
        })}
      </>
    );
  }
  if (getView === "paid") {
    return (
      <>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setView("month")}
        >
          Vue mois
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setView("firm")}
        >
          Vue firme
        </Button>
        <Typography variant="h1">Vue compte</Typography>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setToggle(!getToggle)}
        >
          Changer ordre
        </Button>
        <SpacedDivider space={40} />
        {allMonthElements
          .filter((monthElement) => monthElement.paidAt)
          .sort(
            (monthElementA, monthElementB) =>
              ((monthElementB.paidAt?.getTime() || 0) -
                (monthElementA.paidAt?.getTime() || 0)) *
              (getToggle ? 1 : -1),
          )
          .map((monthElement, index) => (
            <div key={index} style={{ width: "100%" }}>
              <SpacedDivider space={2} />
              <div style={{ display: "flex", width: "90%" }}>
                <div style={{ width: "30%" }}>
                  {monthElement.firmName} - {monthElement.month}
                </div>
                <div style={{ width: "20%", textAlign: "right" }}>
                  {formatPrice2(monthElement.price)
                    .replace(".", "'")
                    .replace(",", ".")}
                </div>
                {monthElement.paidAt && (
                  <div
                    style={{
                      width: "30%",
                      textAlign: "right",
                    }}
                  >
                    <Button
                      disabled={loading}
                      variant="text"
                      onClick={() =>
                        `${index}` === getActive
                          ? setActive(null)
                          : setActive(`${index}`)
                      }
                    >
                      {makeItTwo(monthElement.paidAt.getDate())}/
                      {makeItTwo(monthElement.paidAt.getMonth() + 1)}/
                      {monthElement.paidAt.getFullYear()}
                    </Button>
                  </div>
                )}
                <div style={{ width: "20%", textAlign: "right" }}>
                  {`${index}` === getActive && (
                    <Button
                      disabled={loading}
                      variant="contained"
                      onClick={() => unpay(monthElement.ids)}
                    >
                      Annuler paiement
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
  return <></>;
};
