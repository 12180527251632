import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";

interface Props {
  label: string;
  qty: number;
  bulk: boolean;
  active: boolean;
  setActive: () => void;
}

export const ActiveCard: React.FC<Props> = ({
  label,
  qty,
  bulk,
  active,
  setActive,
}) => {
  return (
    <Grid item md={4} xs={6}>
      <Card
        style={{
          height: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          WebkitUserSelect: "none",
        }}
        elevation={active ? 10 : 2}
        onClick={setActive}
      >
        <Typography variant="h5" color={active ? "primary" : "textPrimary"}>
          {label}
        </Typography>
        <Typography
          variant={active ? "h4" : "h5"}
          color={active ? "primary" : "textPrimary"}
        >
          {qty}
          {bulk && " (vrac)"}
        </Typography>
      </Card>
    </Grid>
  );
};
