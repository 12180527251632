import React from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  SpacedDivider,
  MyDateTimePicker,
  StyledLink,
} from "@bjelos-farm/lib-client-common";
import {
  LocationType,
  ProductFragment,
  useOrderQuery,
  useModifyOrderMutation,
  useModifyIsolatedOrderMutation,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { ActiveCard } from "../../Components/OrderUpdate/ActiveCard";
import { ToAddCard } from "../../Components/OrderUpdate/ToAddCard";
import { Numpad } from "../../Components/OrderUpdate/Numpad";

type ProductPricePossibilitesType = { name: string; id: number };
type OrderElementType = { qty: number; bulk: boolean; productPriceId: number };

const getPricePossibilites = (
  products: ProductFragment[],
  locationType: LocationType,
): ProductPricePossibilitesType[] =>
  products.reduce<ProductPricePossibilitesType[]>((prev, current) => {
    const id = current.productPrices?.find(
      (price) => price.locationType === locationType,
    )?.id;
    return id !== undefined && !current.disabled
      ? [
          ...prev,
          {
            id,
            name: current.category?.name
              ? `${current.category.name} - ${current.name}`
              : current.name,
          },
        ]
      : prev;
  }, []);

export const Update: React.FC = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [getRedirect, setRedirect] = React.useState<boolean>(false);

  const [getFullScreen, setFullScreen] = React.useState<Boolean>(true);
  const [getDate, setDate] = React.useState<Date>(new Date());
  const [getOrders, setOrders] = React.useState<OrderElementType[]>([]);
  const [getActiveId, setActiveId] = React.useState<number | undefined>();

  React.useEffect(() => {
    document.body.style.overscrollBehaviorY = "contain";
    return () => {
      document.body.style.overscrollBehaviorY = "auto";
    };
  }, []);

  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { products, refetch } = useData();
  const { data, loading } = useOrderQuery({
    variables: { where: { id: numId } },
    onCompleted: (data) => {
      if (data.order) {
        setDate(new Date(data.order.when));
        setOrders(
          data.order.cart.cartItems?.map((cartItem) => ({
            qty: cartItem.qty,
            bulk: cartItem.bulk,
            productPriceId: cartItem.productPrice.id,
          })) || [],
        );
      }
    },
  });

  const [createOrder] = useModifyOrderMutation({
    update: () => setRedirect(true),
  });

  const [createIsolatedOrder] = useModifyIsolatedOrderMutation({
    update: () => setRedirect(true),
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const order = data?.order;

  const defaultStock = !order
    ? []
    : order.location.fullStock.cartItems
        ?.filter((item) => !item.productPrice.product.disabled)
        ?.map((cartItem) => ({
          qty: cartItem.qty,
          bulk: cartItem.bulk,
          productPriceId: cartItem.productPrice.id,
        })) || [];

  const pricePossibilites = !order
    ? []
    : getPricePossibilites(products, order.location.locationType).map(
        ({ id, name }) => {
          const qty = defaultStock.find(
            ({ productPriceId }) => productPriceId === id,
          )?.qty;
          return {
            id,
            name: qty ? `${name} (${qty})` : name,
          };
        },
      );

  if (getRedirect) return <Redirect to="/delivery" />;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!order) {
    return <p>Element non trouvé</p>;
  }

  const callSchedule =
    order.calls && order.calls[0] && order.calls[0].callSchedule;
  return (
    <Card
      style={
        getFullScreen
          ? {
              position: "fixed",
              background: "white",
              zIndex: 9999,
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
            }
          : {}
      }
    >
      <CardActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ height: "10vh" }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setFullScreen(!getFullScreen)}
          >
            {getFullScreen ? "Sortir du pleine écran" : "Pleine écran"}
          </Button>
          <StyledLink to="/delivery">
            <Button color="primary" variant="outlined">
              Retour
            </Button>
          </StyledLink>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (callSchedule) {
                createOrder({
                  variables: {
                    toDisableOrder: { id: numId },
                    where: { id: callSchedule.id },
                    orderCreate: {
                      location: { connect: { id: order.location.id } },
                      when: getDate,
                      cart: {
                        create: {
                          cartItems: {
                            create: getOrders
                              .filter((order) => order.qty > 0)
                              .map(({ bulk, qty, productPriceId }) => ({
                                qty,
                                bulk,
                                productPrice: {
                                  connect: { id: productPriceId },
                                },
                              })),
                          },
                        },
                      },
                    },
                  },
                });
              } else {
                createIsolatedOrder({
                  variables: {
                    toDisableOrder: { id: numId },
                    orderCreate: {
                      location: { connect: { id: order.location.id } },
                      when: getDate,
                      cart: {
                        create: {
                          cartItems: {
                            create: getOrders
                              .filter((order) => order.qty > 0)
                              .map(({ bulk, qty, productPriceId }) => ({
                                qty,
                                bulk,
                                productPrice: {
                                  connect: { id: productPriceId },
                                },
                              })),
                          },
                        },
                      },
                    },
                  },
                });
              }
            }}
          >
            Modifier la commande
          </Button>
        </Grid>
      </CardActions>
      <CardContent>
        <Grid
          container
          spacing={3}
          direction={largeScreen ? "row" : "column-reverse"}
        >
          <Grid
            item
            md={8}
            xs={12}
            style={{ height: "90vh", overflowY: "scroll" }}
          >
            <Grid container spacing={3}>
              {getOrders.map(({ bulk, qty, productPriceId }, index) => (
                <ActiveCard
                  key={index}
                  bulk={bulk}
                  qty={qty}
                  label={
                    pricePossibilites.find(({ id }) => productPriceId === id)
                      ?.name || "Nom introuvable"
                  }
                  active={productPriceId === getActiveId}
                  setActive={() => setActiveId(productPriceId)}
                />
              ))}
            </Grid>
            <SpacedDivider space={30} />
            <Typography variant="h4" gutterBottom>
              Ajouter à la commande
            </Typography>
            <Grid container spacing={3}>
              {pricePossibilites
                .filter(
                  (possibility) =>
                    getOrders.findIndex(
                      (order) => order.productPriceId === possibility.id,
                    ) === -1,
                )
                .map(({ id, name }, index) => (
                  <ToAddCard
                    key={index}
                    label={name}
                    onClick={() => {
                      setOrders([
                        ...getOrders,
                        { bulk: false, productPriceId: id, qty: 0 },
                      ]);
                      setActiveId(id);
                    }}
                  />
                ))}
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <MyDateTimePicker
              value={getDate}
              onChange={(value) => setDate(value)}
              label="Date de livraison"
            />
            <SpacedDivider space={5} />
            <Typography>
              {order.location.firm.name === order.location.name
                ? `${order.location.name}`
                : `${order.location.firm.name} - ${order.location.name}`}
            </Typography>
            <Typography>{order.location.phones?.join(" - ")}</Typography>
            {callSchedule && callSchedule.comment && (
              <Typography variant="body2">{callSchedule.comment}</Typography>
            )}
            <SpacedDivider space={20} />
            <Numpad
              disabled={!getActiveId}
              value={
                getOrders.find((order) => order.productPriceId === getActiveId)
                  ?.qty || 0
              }
              valueChange={(value) => {
                const currentOrderIndex = getOrders.findIndex(
                  (order) => order.productPriceId === getActiveId,
                );
                if (currentOrderIndex !== -1) {
                  const tempOrders = JSON.parse(
                    JSON.stringify(getOrders),
                  ) as OrderElementType[];
                  tempOrders[currentOrderIndex].qty = value;
                  setOrders(tempOrders);
                }
              }}
              bulk={
                getOrders.find((order) => order.productPriceId === getActiveId)
                  ?.bulk || false
              }
              bulkChange={(bulk) => {
                const currentOrderIndex = getOrders.findIndex(
                  (order) => order.productPriceId === getActiveId,
                );
                if (currentOrderIndex !== -1) {
                  const tempOrders = JSON.parse(
                    JSON.stringify(getOrders),
                  ) as OrderElementType[];
                  tempOrders[currentOrderIndex].bulk = bulk;
                  setOrders(tempOrders);
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
