import React from "react";
import { Font, Page, StyleSheet } from "@react-pdf/renderer";
import { OrderListFragment } from "../../Generated/graphql";
import { Header } from "./Header";
import { Table } from "./Table";
import { Footer } from "./Footer";

Font.register({
  family: "OpenSans",
  fonts: [
    { src: process.env.PUBLIC_URL + "/fonts/OpenSans-Regular.ttf" },
    {
      fontWeight: "bold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-Bold.ttf",
    },
    {
      fontWeight: "semibold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-SemiBold.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "OpenSans",
    fontSize: 9,
    paddingTop: 42,
    paddingLeft: 50,
    paddingRight: 43,
    paddingBottom: 30,
  },
});

interface Props {
  order: OrderListFragment;
  date: Date;
}

export const InvoicePage: React.FC<Props> = ({ order, date }) => {
  return (
    <Page size="A5" style={styles.page}>
      <Header order={order} date={date} />
      <Table order={order} />
      <Footer order={order} />
    </Page>
  );
};
