import React from "react";
import { Text, View } from "@react-pdf/renderer";

export const Footer: React.FC = () => {
  return (
    <View>
      <Text style={{ marginLeft: "auto", marginRight: "auto" }}>
        Payable sous 30 jours.
      </Text>
      <Text style={{ marginLeft: "auto", marginRight: "auto" }}>
        Nous vous remercions de votre confiance et de votre règlement.
      </Text>
      <Text style={{ marginLeft: "auto", marginRight: "auto" }}>
        Avec nos meilleurs salutations,
      </Text>
      <Text style={{ marginLeft: "auto", marginRight: "auto" }}>
        Gala et Fabien
      </Text>
    </View>
  );
};
