import React from "react";
import { Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { GetLocationQuery, OrderStatus } from "../../Generated/graphql";
import { Header } from "./Header";
import { Table } from "./Table";
import { Footer } from "./Footer";
import { humanReadableDate } from "@bjelos-farm/lib-common-tools";
import { priceFormatter } from "@bjelos-farm/lib-client-common";

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

Font.register({
  family: "OpenSans",
  fonts: [
    { src: process.env.PUBLIC_URL + "/fonts/OpenSans-Regular.ttf" },
    {
      fontWeight: "bold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-Bold.ttf",
    },
    {
      fontWeight: "semibold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-SemiBold.ttf",
    },
  ],
});

interface Props {
  location: GetLocationQuery;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "OpenSans",
    fontSize: 9,
    paddingTop: 42,
    paddingLeft: 50,
    paddingRight: 43,
    paddingBottom: 30,
  },
});

type OrderSummary = { date: Date; value: number };

export const InvoicePage: React.FC<Props> = ({ location }) => {
  const activeOrders: OrderSummary[] =
    location.location?.orders
      ?.filter((order) => order.status !== OrderStatus.Canceled)
      .map((order) => {
        const value = (order.cart.cartItems || []).reduce<number>(
          (prev, item) => {
            return prev + item.productPrice.price * item.qty;
          },
          0,
        );
        const date = new Date(order.when);
        return { date, value };
      }) || [];

  const filterByPeriod = (
    orders: OrderSummary[],
    from: Date,
    to: Date,
  ): OrderSummary[] => {
    return orders.filter((order) => order.date > from && order.date <= to);
  };

  const getTvaSum = (orders: OrderSummary[]): number => {
    return orders.reduce<number>((prev, item) => {
      return prev + Math.floor(item.value * 0.025);
    }, 0);
  };

  const ordersQ1 = filterByPeriod(
    activeOrders,
    new Date("01.01.2021"),
    new Date("04.01.2021"),
  );
  const ordersQ2 = filterByPeriod(
    activeOrders,
    new Date("04.01.2021"),
    new Date("07.01.2021"),
  );
  const ordersQ3 = filterByPeriod(
    activeOrders,
    new Date("07.01.2021"),
    new Date("09.01.2021"),
  );

  const TvaQ1 = getTvaSum(ordersQ1);
  const TvaQ2 = getTvaSum(ordersQ2);
  const TvaQ3 = getTvaSum(ordersQ3);

  return (
    <Page size="A4" style={styles.page}>
      <Header location={location} />
      <Table values={[TvaQ1, TvaQ2, TvaQ3]} />
      <View
        style={{
          marginTop: 20,
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View>
          <Text
            style={{
              fontWeight: "semibold",
              fontSize: 10,
            }}
          >
            Détail premier trimestre
          </Text>
          {ordersQ1
            .sort((val1, val2) => val1.date.valueOf() - val2.date.valueOf())
            .map((order, index) => (
              <Text key={index}>
                {humanReadableDate(order.date).slice(8)} -{" "}
                {formatPrice2(order.value * 0.025)}
              </Text>
            ))}
        </View>
        <View>
          <Text
            style={{
              fontWeight: "semibold",
              fontSize: 10,
            }}
          >
            Détail deuxième trimestre
          </Text>
          {ordersQ2
            .sort((val1, val2) => val1.date.valueOf() - val2.date.valueOf())
            .map((order, index) => (
              <Text key={index}>
                {humanReadableDate(order.date).slice(8)} -{" "}
                {formatPrice2(order.value * 0.025)}
              </Text>
            ))}
        </View>
        <View>
          <Text
            style={{
              fontWeight: "semibold",
              fontSize: 10,
            }}
          >
            Détail pour juillet et août 2021
          </Text>
          {ordersQ3
            .sort((val1, val2) => val1.date.valueOf() - val2.date.valueOf())
            .map((order, index) => (
              <Text key={index}>
                {humanReadableDate(order.date).slice(8)} -{" "}
                {formatPrice2(order.value * 0.025)}
              </Text>
            ))}
        </View>
      </View>

      <Footer total={TvaQ1 + TvaQ2 + TvaQ3} />
    </Page>
  );
};
