import React from "react";
import { Page, StyleSheet } from "@react-pdf/renderer";
import { LocationPrintQueryFragmentFragment } from "../../Generated/graphql";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Table } from "./Table";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingRight: 20,
    paddingLeft: 50,
    paddingBottom: 60,
  },
  date: {
    marginTop: "auto",
  },
});

interface Props {
  location: LocationPrintQueryFragmentFragment;
  datetext: string;
}

export const InvoicePage: React.FC<Props> = ({ location, datetext }) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header location={location} datetext={datetext} />
      <Table location={location} />
      <Footer />
    </Page>
  );
};
