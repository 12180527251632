import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  OrderListFragment,
  CartItemFragment,
  StickerColor,
} from "../../Generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}));

interface Props {
  data: OrderListFragment;
}

interface ItemProps {
  item: CartItemFragment;
}

export const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <Typography variant="body2">
      {item.qty} *{" "}
      {item.productPrice.product.category?.name &&
        `${item.productPrice.product.category?.name} - `}
      {item.productPrice.product.name}
      {item.bulk && " (vrac)"}
    </Typography>
  );
};

export const MyCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  let locDetail: string[] = [];
  if (data.location.printBarcode) {
    locDetail.push("Code barre");
  }
  if (data.location.printDate) {
    locDetail.push("Date");
  }
  if (data.location.stickerColor === StickerColor.Red) {
    locDetail.push("Rouge");
  }
  if (data.location.stickerColor === StickerColor.Green) {
    locDetail.push("Vert");
  }
  return (
    <Grid item lg={3} md={4} xs={12}>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {data.location.firm.name === data.location.name
              ? `${data.location.name}`
              : `${data.location.firm.name} - ${data.location.name}`}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h6"
          >
            {locDetail.join(", ")}
          </Typography>
          {data.cart.cartItems &&
            data.cart.cartItems
              .sort(
                (item1, item2) =>
                  item1.productPrice.product.order +
                  (item1.productPrice.product.category?.order ?? 100) * 100 -
                  (item2.productPrice.product.order +
                    (item2.productPrice.product.category?.order ?? 100) * 100),
              )
              .map((item, index) => <Item item={item} key={index} />)}
        </CardContent>
        <Box flexGrow={1} />
      </Card>
    </Grid>
  );
};
