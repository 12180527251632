import React from "react";
import { Document } from "@react-pdf/renderer";
import { GetLocationQuery } from "../../Generated/graphql";
import { InvoicePage } from "./InvoicePage";

interface Props {
  location: GetLocationQuery;
  title: string;
}

export const SemestrialRecap: React.FC<Props> = ({ location, title }) => {
  return (
    <Document title={title}>
      <InvoicePage location={location} />
    </Document>
  );
};
