import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { makeItTwo } from "@bjelos-farm/lib-common-tools";
import { LocationPrintQueryFragmentFragment } from "../../Generated/graphql";

const styles = StyleSheet.create({
  table: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  firstRow: {
    flexDirection: "row",
    width: "100%",
    borderWidth: 2,
  },
  firstQty: {
    width: "50%",
    padding: 2,
    borderRightWidth: 2,
    borderTopWidth: 2,
    borderLeftWidth: 2,
  },
  firstDate: {
    width: "50%",
    borderRightWidth: 2,
    flexDirection: "row",
  },
  firstName: {
    width: "26%",
    padding: 2,
    borderRightWidth: 2,
  },
  firstUnitPrice: {
    width: "12%",
    padding: 2,
    borderRightWidth: 2,
  },
  firstItemPrice: {
    width: "12%",
    padding: 2,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  date: {
    padding: 2,
    borderLeftWidth: 1,
    borderLeftStyle: "dotted",
  },
  noMarginDate: {
    padding: 2,
  },
  qty: {
    width: "50%",
    borderRightWidth: 1,
    flexDirection: "row",
  },
  name: {
    width: "26%",
    padding: 2,
    borderRightWidth: 1,
  },
  unitPrice: {
    width: "12%",
    padding: 2,
    borderRightWidth: 1,
  },
  itemPrice: {
    width: "12%",
    padding: 2,
  },
  total: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "auto",
    width: "40%",
    padding: 2,
    borderWidth: 2,
  },
});

interface Props {
  location: LocationPrintQueryFragmentFragment;
}

export const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

export const priceFormatter3 = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export const formatPrice3 = (input: number) =>
  priceFormatter3.format(input / 100);

type ProductSummary = {
  id: number;
  order: number;
  name: string;
  price: number;
  sums: { sum: number; detail: { date: number; qty: number }[] };
};

export const Table: React.FC<Props> = ({ location }) => {
  if (!location.orders || !location.orders[0]) return <></>;
  const dateExtension = ".04.2021";
  const sums: ProductSummary[] = location.orders
    .reduce<ProductSummary[]>((prev, order) => {
      if (!order.cart.cartItems) {
        return prev;
      }
      const day = new Date(order.when).getDate();
      return order.cart.cartItems.reduce<ProductSummary[]>((tempPrev, item) => {
        const productId = item.productPrice.product.id;
        const existingProduct = tempPrev.find(
          (product) => product.id === productId,
        );
        if (!existingProduct) {
          tempPrev.push({
            id: productId,
            order:
              item.productPrice.product.order +
              100 * (item.productPrice.product.category?.order ?? 100),
            price: item.productPrice.price,
            name: item.productPrice.product.category
              ? `${item.productPrice.product.category.name} - ${item.productPrice.product.name}`
              : item.productPrice.product.name,
            sums: { sum: item.qty, detail: [{ qty: item.qty, date: day }] },
          });
        } else {
          existingProduct.sums.sum += item.qty;
          existingProduct.sums.detail.push({ qty: item.qty, date: day });
        }
        return tempPrev;
      }, JSON.parse(JSON.stringify(prev)));
    }, [])
    .sort((product1, product2) => product1.order - product2.order);

  const total =
    sums.reduce<number>(
      (previous, current) => previous + current.price * current.sums.sum,
      0,
    ) || 0;

  const width = Math.floor(100 / ((location.orders?.length || 1) + 1)) + "%";
  return (
    <View style={styles.table}>
      <View style={styles.firstQty}>
        <Text
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Quantité
        </Text>
      </View>
      <View style={styles.firstRow}>
        <View style={styles.firstDate}>
          {location.orders.map((_, index) => {
            return (
              <View
                style={{
                  ...(index === 0 ? styles.noMarginDate : styles.date),
                  width: width,
                }}
              >
                <Text
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  L{index + 1}
                </Text>
              </View>
            );
          })}
          <View style={{ ...styles.date, width: width }}>
            <Text
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Total
            </Text>
          </View>
        </View>
        <View style={styles.firstName}>
          <Text>Désignation</Text>
        </View>
        <View style={styles.firstUnitPrice}>
          <Text
            style={{
              marginLeft: "auto",
            }}
          >
            Prix unité
          </Text>
        </View>
        <View style={styles.firstItemPrice}>
          <Text
            style={{
              marginLeft: "auto",
            }}
          >
            Prix
          </Text>
        </View>
      </View>
      {sums.map((item, index) => {
        return (
          <View style={styles.row} key={index}>
            <View style={styles.qty}>
              {(location.orders || []).map((order, index) => {
                const day = new Date(order.when).getDate();
                const value =
                  item.sums.detail.find((detail) => detail.date === day)?.qty ||
                  0;
                return (
                  <View
                    style={{
                      ...(index === 0 ? styles.noMarginDate : styles.date),
                      width: width,
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {value || ""}
                    </Text>
                  </View>
                );
              })}
              <View style={{ ...styles.date, width: width }}>
                <Text
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {item.sums.sum}
                </Text>
              </View>
            </View>
            <View style={styles.name}>
              <Text>{item.name}</Text>
            </View>
            <View style={styles.unitPrice}>
              <Text
                style={{
                  marginLeft: "auto",
                }}
              >
                {formatPrice3(item.price)}
              </Text>
            </View>
            <View style={styles.itemPrice}>
              <Text
                style={{
                  marginLeft: "auto",
                }}
              >
                {formatPrice2(item.price * item.sums.sum)}
              </Text>
            </View>
          </View>
        );
      })}
      <View style={styles.total}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>Total (CHF):</Text>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>
          {formatPrice2(total)}
        </Text>
      </View>
      {location.orders.map((order, index) => {
        const day = new Date(order.when).getDate();
        if (day === 31) {
          return (
            <View key={index}>
              <Text style={{ color: "red" }}>
                Suite à une erreur technique, la facture de mars n’incluait pas
                la livraison du mercredi 31 mars. Cette dernière a été rajouter
                sur la présente facture. Veuillez-nous excusez du dérangement.
              </Text>
              <Text>
                L{index + 1} : Livraison du {makeItTwo(day)}.03.2021
                {order.externalRef ? ` (${order.externalRef})` : ""}
              </Text>
            </View>
          );
        } else {
          return (
            <Text key={index}>
              L{index + 1} : Livraison du {makeItTwo(day)}
              {dateExtension}
              {order.externalRef ? ` (${order.externalRef})` : ""}
            </Text>
          );
        }
      })}
    </View>
  );
};
