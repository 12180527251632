import React from "react";
import { Page, StyleSheet, Text } from "@react-pdf/renderer";
import { OrderListFragment } from "../../Generated/graphql";
import { Header } from "./Header";
import { Table } from "./Table";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingRight: 20,
    paddingLeft: 50,
    paddingBottom: 60,
  },
  date: {
    marginTop: "auto",
  },
});

interface Props {
  order: OrderListFragment;
  date: Date;
}

export const InvoicePage: React.FC<Props> = ({ order, date }) => {
  return (
    <Page size="A5" style={styles.page}>
      <Header order={order} date={date} />
      <Table order={order} />
      <Text style={styles.date}>Acquitté le :</Text>
    </Page>
  );
};
