import React from "react";
import { Font, Page, StyleSheet } from "@react-pdf/renderer";
import { GetLocationQuery, OrderStatus } from "../../Generated/graphql";
import { Header } from "./Header";
import { Table } from "./Table";
import { Footer } from "./Footer";
import { priceFormatter } from "@bjelos-farm/lib-client-common";

export const formatPrice2 = (input: number) =>
  priceFormatter.format(input / 100);

Font.register({
  family: "OpenSans",
  fonts: [
    { src: process.env.PUBLIC_URL + "/fonts/OpenSans-Regular.ttf" },
    {
      fontWeight: "bold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-Bold.ttf",
    },
    {
      fontWeight: "semibold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-SemiBold.ttf",
    },
  ],
});

interface Props {
  location: GetLocationQuery;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "OpenSans",
    fontSize: 9,
    paddingTop: 42,
    paddingLeft: 50,
    paddingRight: 43,
    paddingBottom: 30,
  },
});

type OrderSummary = { date: Date; value: number };

export const InvoicePage: React.FC<Props> = ({ location }) => {
  const activeOrders: OrderSummary[] =
    location.location?.orders
      ?.filter((order) => order.status !== OrderStatus.Canceled)
      .map((order) => {
        const value = (order.cart.cartItems || []).reduce<number>(
          (prev, item) => {
            return prev + item.productPrice.price * item.qty;
          },
          0,
        );
        const date = new Date(order.when);
        return { date, value };
      }) || [];

  const filterByPeriod = (
    orders: OrderSummary[],
    from: Date,
    to: Date,
  ): OrderSummary[] => {
    return orders.filter((order) => order.date > from && order.date <= to);
  };

  const orders = filterByPeriod(
    activeOrders,
    new Date("01.01.2021"),
    new Date("06.30.2021"),
  );
  return (
    <Page size="A4" style={styles.page}>
      <Header location={location} />
      <Table values={orders} />
      <Footer />
    </Page>
  );
};
