import React from "react";
import { Font, Page, StyleSheet } from "@react-pdf/renderer";
import { OrderInLocationShowFragment } from "../../Generated/graphql";
import { Header } from "./Header";
import { Table } from "./Table";
import { Footer } from "./Footer";

Font.register({
  family: "OpenSans",
  fonts: [
    { src: process.env.PUBLIC_URL + "/fonts/OpenSans-Regular.ttf" },
    {
      fontWeight: "bold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-Bold.ttf",
    },
    {
      fontWeight: "semibold",
      src: process.env.PUBLIC_URL + "/fonts/OpenSans-SemiBold.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "OpenSans",
    fontSize: 9,
    paddingTop: 42,
    paddingLeft: 50,
    paddingRight: 43,
    paddingBottom: 30,
  },
});

interface Props {
  order: OrderInLocationShowFragment;
  date: Date;
  firmName: string;
  externalRef: string;
}

export const InvoicePage: React.FC<Props> = ({
  order,
  date,
  firmName,
  externalRef,
}) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header date={date} firmName={firmName} externalRef={externalRef} />
      <Table order={order} />
      <Footer order={order} date={date} />
    </Page>
  );
};
