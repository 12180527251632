import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardActionArea,
  IconButton,
  Theme,
} from "@material-ui/core";
import { Launch, Edit, ExpandMore } from "@material-ui/icons";
import {
  OrderListFragment,
  useSetOrderDeliveredMutation,
  CartItemFragment,
  OrderStatus,
} from "../../Generated/graphql";
import { humanReadableTime } from "@bjelos-farm/lib-common-tools";
import { StyledLink } from "@bjelos-farm/lib-client-common";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Invoice } from "../../Pdf/Invoice";
import { NewInvoice } from "../../Pdf/NewInvoice";
import { PdfDownloadButton } from "../PdfDownloadButton";

export const hasRotation = (theme: Theme, rotation: number): CSSProperties => ({
  transform: `rotate(${rotation}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
});

const useStyles = makeStyles((theme) => ({
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.error.main,
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  data: OrderListFragment;
  refetch: () => void;
}

interface ItemProps {
  item: CartItemFragment;
}

type ButtonType = { text: string; action: () => void } | undefined;

export const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <Typography variant="body2">
      {item.qty} *{" "}
      {item.productPrice.product.category?.name &&
        `${item.productPrice.product.category?.name} - `}
      {item.productPrice.product.name}
      {item.bulk && " (vrac)"}
    </Typography>
  );
};

export const MyCard: React.FC<Props> = ({ data, refetch }) => {
  const [getExpanded, setExpanded] = React.useState<boolean>(false);
  const classes = useStyles();
  const [setDelivered] = useSetOrderDeliveredMutation({
    update: refetch,
  });
  const filename = `facture-${
    data.location.firm.name === data.location.name
      ? `${data.location.firm.name}-${data.location.name}`
      : data.location.name
  }`;

  const button: ButtonType = ((): ButtonType => {
    if (data.deliveredAt) {
      if (data.location.monthly) {
        return undefined;
      } else if (data.status === OrderStatus.Paid) {
        return {
          text: "Noter comme impayé",
          action: () =>
            setDelivered({
              variables: {
                where: { id: data.id },
                data: { status: { set: OrderStatus.Active } },
              },
            }),
        };
      } else {
        return {
          text: "Noter comme payé",
          action: () =>
            setDelivered({
              variables: {
                where: { id: data.id },
                data: { status: { set: OrderStatus.Paid } },
              },
            }),
        };
      }
    } else {
      if (data.location.monthly) {
        return {
          text: "Livraison finie",
          action: () =>
            setDelivered({
              variables: {
                where: { id: data.id },
                data: { deliveredAt: { set: new Date() } },
              },
            }),
        };
      } else {
        return {
          text: "Livraison finie",
          action: () =>
            setDelivered({
              variables: {
                where: { id: data.id },
                data: {
                  deliveredAt: { set: new Date() },
                  status: { set: OrderStatus.Paid },
                },
              },
            }),
        };
      }
    }
  })();
  const InvoiceToUse =
    new Date(data.when).valueOf() < 1630447200000 ? Invoice : NewInvoice;

  return (
    <Grid item lg={3} md={4} xs={12}>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {data.location.firm.name === data.location.name
              ? `${data.location.name}`
              : `${data.location.firm.name} - ${data.location.name}`}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            {data.location.address}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
          >
            à {humanReadableTime(new Date(data.when))}
          </Typography>
          <pre>
            {(data.calls &&
              data.calls[0] &&
              data.calls[0] &&
              data.calls[0].callSchedule.comment) ||
              ""}
          </pre>
          <IconButton
            className={getExpanded ? classes.expandOpen : classes.expand}
            onClick={() => setExpanded((expended) => !expended)}
          >
            <ExpandMore />
          </IconButton>
          {getExpanded &&
            data.cart.cartItems &&
            data.cart.cartItems.map((item, index) => (
              <Item item={item} key={index} />
            ))}
          {getExpanded && (
            <Grid container direction="row" justify="space-between">
              <StyledLink to={`/delivery/${data.id}/update`}>
                <IconButton>
                  <Edit />
                </IconButton>
              </StyledLink>
              <PdfDownloadButton
                filename={filename}
                document={
                  <InvoiceToUse
                    orders={[data]}
                    title={filename}
                    date={new Date(data.when)}
                  />
                }
              />
            </Grid>
          )}
        </CardContent>
        <Box flexGrow={1} />
        <Divider />
        {button && (
          <CardActionArea onClick={button.action}>
            <Box p={2}>
              <Grid container justify="center" spacing={2}>
                <Grid className={classes.statsItem} item>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    {button.text}
                  </Typography>
                  <Launch className={classes.statsIcon} color="action" />
                </Grid>
              </Grid>
            </Box>
          </CardActionArea>
        )}
      </Card>
    </Grid>
  );
};
