import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
// import { PDFViewer } from "@react-pdf/renderer";
import { textFromMonth } from "@bjelos-farm/lib-common-tools";
import {
  SingleSelect,
  SpacedDivider,
  StyledLink,
} from "@bjelos-farm/lib-client-common";
import { useLocationForPrintLazyQuery } from "../../Generated/graphql";
import { ExposureNeg1, PlusOne } from "@material-ui/icons";
import { Montly } from "../../Pdf/Montly";
import { NewMontly } from "../../Pdf/NewMontly";
import { AprilMonth } from "../../Pdf/AprilMonth";
import { PdfDownloadButton } from "../../Components/PdfDownloadButton";
import { slufigy } from "../../Utils/slugify";

export const Month: React.FC = () => {
  const [query, { data, loading, refetch }] = useLocationForPrintLazyQuery();
  const [getMonth, setMonth] = React.useState<number>(
    new Date().getMonth() - 1,
  );

  const locations =
    data?.locations.filter(
      (location) => location.orders && location.orders.length > 0,
    ) || [];

  React.useEffect(() => {
    const minday = getMonth <= 3 ? 0 : 1;
    const maxday = getMonth <= 2 ? 0 : 1;
    const minDate = new Date(2021, getMonth, minday, 0, 0, 0, 0);
    const maxDate = new Date(2021, getMonth + 1, maxday, 0, 0, 0, 0);
    query({
      variables: {
        maxDate,
        minDate,
      },
    });
  }, [getMonth, query]);

  const InvoiceToUse =
    getMonth === 3 ? AprilMonth : getMonth >= 8 ? NewMontly : Montly;

  if (loading) {
    return <p>Chargement</p>;
  }

  const datetext =
    getMonth >= 8
      ? `${textFromMonth(getMonth)} 2021`
      : `Livraisons de ${textFromMonth(getMonth).toLowerCase()} 2021`;

  // return (
  //   <div style={{ height: 900, width: 900 }}>
  //     <PDFViewer style={{ height: "100%", width: "100%" }}>
  //       <InvoiceToUse
  //         locations={locations.filter((location, index) => index === 0)}
  //         title={`facture-complet-2021-${slufigy(textFromMonth(getMonth))}`}
  //         datetext={datetext}
  //       />
  //     </PDFViewer>
  //   </div>
  // );
  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 20 }}>
        <Button variant="contained" onClick={() => refetch && refetch()}>
          Rafraichir
        </Button>
        <StyledLink to="/print">
          <Button variant="contained">Jours</Button>
        </StyledLink>
      </Grid>
      <Grid container>
        <IconButton onClick={() => setMonth(getMonth > 0 ? getMonth - 1 : 0)}>
          <ExposureNeg1 color="primary" />
        </IconButton>
        <div style={{ width: 200 }}>
          <SingleSelect
            currentValue={getMonth}
            label="Mois"
            disableClearable
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => ({
              label: textFromMonth(month),
              value: month,
            }))}
            setNewValue={(value) => value !== undefined && setMonth(value)}
          />
        </div>
        <IconButton onClick={() => setMonth(getMonth < 11 ? getMonth + 1 : 11)}>
          <PlusOne color="primary" />
        </IconButton>
      </Grid>
      <SpacedDivider space={40} />
      <PdfDownloadButton
        showfilename
        filename={`facture-complet-2021-${slufigy(textFromMonth(getMonth))}`}
        document={
          <InvoiceToUse
            locations={locations}
            title={`facture-complet-2021-${slufigy(textFromMonth(getMonth))}`}
            datetext={datetext}
          />
        }
      />
      <SpacedDivider space={40} />
      {locations.map((location, index) => {
        const firmName =
          location.name === location.firm.name
            ? location.name
            : `${location.firm.name} ${location.name}`;
        const filename = `facture-${firmName}-2021-${slufigy(
          textFromMonth(getMonth),
        )}`;
        return (
          <PdfDownloadButton
            key={index}
            showfilename
            filename={filename}
            document={
              <InvoiceToUse
                locations={[location]}
                title={filename}
                datetext={datetext}
              />
            }
          />
        );
      })}
    </>
  );
};
