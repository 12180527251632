import React from "react";
import ReactPDF, { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { IconButton } from "@material-ui/core";
import { Print } from "@material-ui/icons";

interface Props {
  showfilename?: boolean;
  filename: string;
  document: React.ReactElement<ReactPDF.DocumentProps>;
}

export const PdfDownloadButton: React.FC<Props> = ({
  showfilename = false,
  filename,
  document,
}) => {
  const [getBlob, setBlob] = React.useState<Blob>();
  const [getLoading, setLoading] = React.useState<boolean>(false);
  const download = async () => {
    if (getLoading) {
      return;
    } else if (getBlob) {
      saveAs(getBlob, filename);
    } else {
      setLoading(true);
      const blob = await pdf(document).toBlob();
      setBlob(blob);
      saveAs(blob, filename);
      setLoading(false);
    }
  };
  if (showfilename) {
    return (
      <div onClick={download}>
        <IconButton>
          <Print />
        </IconButton>
        {filename}
      </div>
    );
  }
  return (
    <IconButton onClick={download}>
      <Print />
    </IconButton>
  );
};
